import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { IconTypes } from "../../../components/icons.types";
import { SearchBox } from "../../../components/SearchBox";
import { DataTypeName } from "../../../features/data/data-types";
import { CategorySelect } from "../../products/categories/CategorySelect";
import { useAtom } from "jotai";
import { productDbAtom } from "../../../features/products/product.state";
import { useDialogs } from "../../../contexts/useDialogs";
import { FixedPanel } from "../../../components/FixedPanel";
import { ProductField } from "../../../features/products/product";
import { CreateItemAttrs } from "../../../features/catalogs/catalog.design";
import { DesignBoardRef } from "../components/DesignerBoard";

// Field type definitions with icons and colors
const fieldTypes = {
  [DataTypeName.Text]: { icon: undefined, color: "text.secondary" },
  [DataTypeName.RichText]: {
    icon: IconTypes.RichText,
    color: "text.secondary",
  },
  [DataTypeName.Image]: { icon: IconTypes.Image, color: "success.main" },
  [DataTypeName.Money]: { icon: IconTypes.Money, color: "error.main" },
  [DataTypeName.Number]: { icon: IconTypes.Number, color: "info.main" },
  [DataTypeName.Date]: { icon: IconTypes.Date, color: "warning.main" },
};

const builtinFields = [
  { name: "Name", dataType: DataTypeName.Text },
  { name: "Category", dataType: DataTypeName.Text },
];

type FieldEntry = ProductField & { placeholder: string };

const InsertDialog = ({
  open,
  onClose,
  onInsert,
  field,
}: {
  open: boolean;
  onClose: () => void;
  onInsert: (action: "new" | "select") => void;
  field: FieldEntry;
}) => {
  const isImage = field?.dataType === DataTypeName.Image;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Insert {field?.name} {isImage && "(Image Field)"}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {isImage
            ? "This is an image field. Would you like to:"
            : "No text box is currently selected. Would you like to:"}
        </Typography>
        <List
          sx={{
            "& .MuiListItemButton-root:hover": {
              bgcolor: "action.hover",
            },
          }}
        >
          <ListItem disablePadding>
            <ListItemButton onClick={() => onInsert("new")}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  py: 1,
                }}
              >
                <Box sx={{ pr: 2, color: "primary.main" }}>
                  {isImage ? <IconTypes.Image /> : <IconTypes.Add />}
                </Box>
                <ListItemText
                  primary={
                    isImage
                      ? "Create a new image element"
                      : "Create a new text box with this field"
                  }
                  secondary={
                    isImage
                      ? "Adds a new image element that displays this product image"
                      : "Adds a new text element containing this field reference"
                  }
                />
              </Box>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={() => onInsert("select")}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  py: 1,
                }}
              >
                <Box sx={{ pr: 2, color: "info.main" }}>
                  <IconTypes.Edit />
                </Box>
                <ListItemText
                  primary={
                    isImage
                      ? "Select and edit a text box first"
                      : "Select an existing text box first"
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      Double click any text box to edit it, then try inserting
                      again
                    </Typography>
                  }
                />
              </Box>
            </ListItemButton>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type Props = {
  categoryId?: string;
  boardRef: React.MutableRefObject<DesignBoardRef>;
};

const ProductFieldsPanel = ({ categoryId, boardRef }: Props) => {
  const [productDb] = useAtom(productDbAtom);
  const { productModalApi } = useDialogs();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    categoryId || ""
  );
  const [selectedField, setSelectedField] = useState(null);

  const api = useCallback(() => boardRef.current?.api, [boardRef]);

  const fields: FieldEntry[] = useMemo(() => {
    const category = productDb.categoryMap.get(selectedCategoryId);

    if (!category?.group.fields) return [];

    const categoryFields = [...builtinFields, ...category.group.fields].map(
      (field) => ({
        ...field,
        placeholder: `{{${field.name}}}`,
      })
    );

    const options =
      category.options.reduce((a, o) => `${a} {{${o.name}}}`, "") ?? "";

    const variantField = {
      name: "Variants",
      placeholder: `{{Variants}} {{Image:Fit:50:50}} ${options} {{SKU}} {{Price}} {{Description}} {{/Variants}}`,
      dataType: DataTypeName.RichText,
    };

    return [...categoryFields, variantField].filter((field) =>
      field.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [productDb.categoryMap, searchTerm, selectedCategoryId]);

  const editCategory = useCallback(() => {
    productModalApi.openEditCategory({
      categoryId: selectedCategoryId,
      startTab: "fields",
    });
  }, [productModalApi, selectedCategoryId]);

  const handleFieldAction = useCallback(
    (field: FieldEntry) => {
      if (api().editedHtml) {
        setTimeout(() => {
          const expr =
            field.dataType == DataTypeName.Image
              ? `{{${field.name}:Fit:50:50}}`
              : field.placeholder;
          api().insertText(expr);
        });
        return;
      }

      if (selectedField) {
        if (field.dataType === DataTypeName.Image) {
          api().onChange({
            type: "create-item",
            attrs: {
              type: "product-image",
              content: field.name,
              pos: "none",
            } as CreateItemAttrs,
          });
        } else {
          api().onChange({
            type: "create-item",
            attrs: {
              type: "text",
              content: field.placeholder,
              pos: "none",
            } as CreateItemAttrs,
          });
        }
        return;
      }
      setSelectedField(field);
    },
    [api, selectedField]
  );

  const handleDialogAction = useCallback(
    (action) => {
      if (!selectedField) return;
      if (action === "new") {
        handleFieldAction(selectedField);
      }
      setSelectedField(null);
    },
    [handleFieldAction, selectedField]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: "background.paper",
        borderRadius: 1,
        overflow: "hidden",
      }}
    >
      <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
        <Typography variant="subtitle1" gutterBottom>
          Product Fields
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Click a field to insert into edited text box
        </Typography>

        <Box sx={{ display: "flex", gap: 2 }}>
          <SearchBox
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search fields..."
            size="small"
          />
          <CategorySelect
            categoryId={selectedCategoryId}
            onChange={setSelectedCategoryId}
            sx={{ minWidth: 200 }}
          />
          <Tooltip title="Edit category">
            <IconButton onClick={editCategory} size="small">
              <IconTypes.Edit fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <FixedPanel>
        <List
          sx={{
            flex: 1,
            overflow: "auto",
            "& .MuiListItemButton-root:hover": {
              bgcolor: "action.hover",
            },
          }}
        >
          {fields.map((field) => {
            const FieldIcon =
              fieldTypes[field.dataType]?.icon || IconTypes.Text;
            const iconColor =
              fieldTypes[field.dataType]?.color || "text.secondary";

            return (
              <ListItem
                key={field.name}
                disablePadding
                secondaryAction={
                  <Tooltip title="Insert field">
                    <IconButton
                      edge="end"
                      onClick={() => handleFieldAction(field)}
                      size="small"
                    >
                      <IconTypes.Add fontSize="small" />
                    </IconButton>
                  </Tooltip>
                }
              >
                <ListItemButton
                  onClick={() => handleFieldAction(field)}
                  sx={{
                    px: 2,
                  }}
                >
                  <Box
                    sx={{
                      mr: 2,
                      display: "flex",
                      alignItems: "center",
                      color: iconColor,
                    }}
                  >
                    <FieldIcon />
                  </Box>
                  <ListItemText
                    primary={
                      <Typography variant="body2">{field.name}</Typography>
                    }
                    secondary={
                      <Typography variant="caption" sx={{ opacity: 0.7 }}>
                        {field.placeholder}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}

          {fields.length === 0 && (
            <Box sx={{ p: 3, textAlign: "center" }}>
              <Typography variant="body2" color="text.secondary">
                No fields found
              </Typography>
            </Box>
          )}
        </List>
      </FixedPanel>
      <InsertDialog
        open={!!selectedField}
        onClose={() => {
          setSelectedField(null);
        }}
        onInsert={handleDialogAction}
        field={selectedField}
      />
    </Box>
  );
};

export default ProductFieldsPanel;
