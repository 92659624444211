import CropOriginalSharpIcon from "@mui/icons-material/CropOriginalSharp";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import CategoryIcon from "@mui/icons-material/Category";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";

import ViewModuleIcon from "@mui/icons-material/ViewModule";
import GridViewIcon from "@mui/icons-material/GridView";

import LayersIcon from "@mui/icons-material/Layers";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import BorderStyleIcon from "@mui/icons-material/BorderStyle";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ExtensionIcon from "@mui/icons-material/Extension";
import PhotoAlbumOutlinedIcon from "@mui/icons-material/PhotoAlbumOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import WallpaperIcon from "@mui/icons-material/Wallpaper";

import ScienceIcon from "@mui/icons-material/Science";
import SettingsIcon from "@mui/icons-material/Settings";
import ShareIcon from "@mui/icons-material/Share";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CheckIcon from "@mui/icons-material/Check";
import DataObjectIcon from "@mui/icons-material/DataObject";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import BackupIcon from "@mui/icons-material/Backup";
import RestoreIcon from "@mui/icons-material/Restore";
import PublishIcon from "@mui/icons-material/Publish";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import BarChartIcon from "@mui/icons-material/BarChart";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import TocIcon from "@mui/icons-material/Toc";
import TableRowsIcon from "@mui/icons-material/TableRows";
import LaunchIcon from "@mui/icons-material/Launch";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import NumbersIcon from "@mui/icons-material/Numbers";
import EventIcon from "@mui/icons-material/Event";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import ClearIcon from "@mui/icons-material/Clear";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import HomeIcon from "@mui/icons-material/Home";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

import SearchIcon from "@mui/icons-material/Search";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import TuneIcon from "@mui/icons-material/Tune";
import StorefrontIcon from "@mui/icons-material/Storefront";

import ChatIcon from "@mui/icons-material/Chat";
import Awesome from "@mui/icons-material/AutoAwesomeOutlined";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";

export const IconTypes = {
  ImagePlaceholder: CropOriginalSharpIcon,
  TemplatePlaceholder: LayersOutlinedIcon,
  TextPlaceholder: TextFieldsIcon,

  Design: DesignServicesIcon,
  Product: CategoryIcon,
  Image: ImageOutlinedIcon,
  Catalog: ImportContactsIcon,
  Collection: AccountTreeOutlinedIcon,
  Users: GroupOutlinedIcon,
  Category: WidgetsOutlinedIcon,

  ProductCard: ViewQuiltIcon,
  Layout: PhotoAlbumOutlinedIcon,
  Element: ExtensionIcon,
  ImageNoFrame: LandscapeOutlinedIcon,

  RemoveAction: RemoveCircleOutlineIcon,
  AddAction: AddCircleOutlineIcon,
  EditAction: EditIcon,
  DeleteAction: DeleteOutlineIcon,
  CopyAction: ContentCopyIcon,
  Add: AddIcon,
  AddInline: PlaylistAddOutlinedIcon,
  AddPopup: LibraryAddOutlinedIcon,
  Clear: ClearIcon,
  Reset: RestartAltIcon,

  Grid: ViewModuleIcon,
  Layers: LayersIcon,
  Pages: AutoStoriesIcon,
  Text: TextFormatIcon,
  RichText: FormatAlignLeftIcon,
  BlankPage: InsertDriveFileOutlinedIcon,
  Background: BorderStyleIcon,
  ProductList: RequestPageOutlinedIcon,
  List: ArticleOutlinedIcon,
  Table: TableRowsIcon,
  Link: LaunchIcon,
  Templates: GridViewIcon,
  AddImage: AddPhotoAlternateOutlinedIcon,
  BackgroundImage: WallpaperIcon,

  Test: ScienceIcon,
  Settings: SettingsIcon,
  Share: ShareIcon,
  Like: ThumbUpOutlinedIcon,
  More: MoreVertIcon,
  Edit: ModeEditOutlineOutlinedIcon,
  Download: DownloadOutlinedIcon,
  PDF: PictureAsPdfIcon,
  OpenInNew: OpenInNewIcon,
  ExpandMore: ExpandMoreIcon,
  ExpandLess: ExpandLessIcon,
  Clipboard: ContentPasteIcon,
  ArrowDropDown: ArrowDropDownIcon,
  Section: FileCopyOutlinedIcon,
  Pause: PauseIcon,
  Play: PlayArrowIcon,
  AlignTop: VerticalAlignTopIcon,
  AlignBottom: VerticalAlignBottomIcon,
  Check: CheckIcon,
  Fields: DataObjectIcon,
  Question: QuestionMarkIcon,
  Backup: BackupIcon,
  Restore: RestoreIcon,
  Dashboard: BarChartIcon,
  Help: HelpIcon,
  HelpOutline: HelpOutlineOutlinedIcon,
  Menu: MenuIcon,
  Playground: SportsEsportsIcon,
  Toc: TocIcon,
  Close: CloseIcon,
  Save: SaveOutlinedIcon,
  SaveLayout: BookmarkAddOutlinedIcon,
  Publish: PublishIcon,
  MenuOpen: MenuOpenIcon,

  DoubleLeftArrow: KeyboardDoubleArrowLeftIcon,
  DoubleRightArrow: KeyboardDoubleArrowRightIcon,
  ArrowForward: ArrowForwardIosIcon,
  ArrowBack: ArrowBackIosIcon,
  ArrowUp: KeyboardArrowUpIcon,
  ArrowDown: KeyboardArrowDownIcon,
  ArrowRight: KeyboardArrowRightIcon,
  ArrowLeft: KeyboardArrowLeftIcon,

  Drag: DragIndicatorIcon,
  NewPage: NoteAddOutlinedIcon,
  Connect: ElectricalServicesIcon,

  Money: AttachMoneyIcon,
  Number: NumbersIcon,
  Date: EventIcon,

  LightMode: LightModeOutlinedIcon,
  DarkMode: DarkModeIcon,
  OpenFullscreen: OpenInFullOutlinedIcon,
  CloseFullscreen: CloseFullscreenOutlinedIcon,

  Upload: FileUploadOutlinedIcon,
  Home: HomeIcon,
  Info: InfoOutlinedIcon,
  CheckCircle: CheckCircleOutlineIcon,
  CheckAll: DoneAllIcon,
  CheckBox: CheckBoxOutlinedIcon,

  Filter: FilterListIcon,
  Sort: SortIcon,
  Tune: TuneIcon,
  Store: StorefrontIcon,
  VisibilityOn: VisibilityOutlinedIcon,
  VisibilityOff: VisibilityOffOutlinedIcon,
  Search: SearchIcon,
  SavedSearch: SavedSearchIcon,

  Chat: ChatIcon,
  Awesome: Awesome,
};
