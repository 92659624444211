import apiClient from "../../services/apiClient";
import { devLog } from "../../services/util";
import { getDecodedToken, isLoggedIn } from "../accounts/auth.service";
import { EventRecord } from "./analytics";

class AnalyticsBatcher {
  private eventQueue: EventRecord[] = [];
  readonly sessionId: string;
  private flushTimeout?: number;
  private readonly FLUSH_INTERVAL_MS = 300000;
  private readonly BATCH_SIZE = 100;

  constructor() {
    const token = getDecodedToken();
    this.sessionId = token?.sessionId || generateFallbackSessionId();
  }

  queueEvent(event: Omit<EventRecord, "sessionId" | "ts">) {
    if (!event.type) {
      if (event.feature || event.tag) event.type = "feature";
      else if (!event.internal) event.type = "trace";
    }

    if (event.type === "feature" && event.value === undefined) event.value = 1;

    this.eventQueue.push({
      ...event,
      sessionId: this.sessionId,
      ts: new Date().toLocaleTimeString(),
      timestamp: new Date().toISOString(),
    });

    // Flush if queue is full
    if (this.eventQueue.length >= this.BATCH_SIZE || event.flush) {
      this.flush(event.flush ? event.category : "batch-full");
      return;
    }

    // Start timeout if not already started
    this.startFlushTimeout();
  }

  private startFlushTimeout() {
    // Don't start a new timeout if one is already running
    if (this.flushTimeout) return;

    // Use a shorter timeout if we have a lot of events already
    const timeoutDuration =
      this.eventQueue.length > this.BATCH_SIZE / 2
        ? this.FLUSH_INTERVAL_MS / 3 // Faster flush for larger queues
        : this.FLUSH_INTERVAL_MS;

    this.flushTimeout = window.setTimeout(() => {
      // Only flush if we have enough events or it's been a while
      if (this.eventQueue.length > 0) {
        this.flush("timeout");
      } else {
        // Just clear the timeout if we have no events
        this.flushTimeout = undefined;
      }
    }, timeoutDuration);
  }

  async flush(reason: string) {
    if (this.eventQueue.length === 0 || !isLoggedIn()) return;

    const events = [...this.eventQueue];
    try {
      this.eventQueue = [];

      // Clear any pending timeout
      if (this.flushTimeout) {
        window.clearTimeout(this.flushTimeout);
        this.flushTimeout = undefined;
      }

      // Safer browser and OS detection
      let userBrowser = navigator.userAgent;
      let browserVersion = "";
      let userOs = "unknown";

      // Use type assertion for the newer API
      const nav = navigator as Navigator & {
        userAgentData?: {
          platform: string;
          brands?: Array<{ brand: string; version: string }>;
        };
      };

      if (nav.userAgentData) {
        // Extract OS information
        userOs = nav.userAgentData.platform || "unknown";

        // Extract the actual browser name and version
        if (nav.userAgentData.brands && nav.userAgentData.brands.length > 0) {
          // Look for recognized browsers in order of preference
          const browserMap = {
            "Google Chrome": "Chrome",
            "Microsoft Edge": "Edge",
            Opera: "Opera",
            Firefox: "Firefox",
            Safari: "Safari",
          };

          // Find the first recognized brand or use the second entry (usually the actual browser)
          for (const brand of nav.userAgentData.brands) {
            if (browserMap[brand.brand]) {
              userBrowser = browserMap[brand.brand];
              browserVersion = brand.version;
              break;
            }
          }

          // If no recognized browser was found, use the second entry
          // (typically Chrome, Edge, etc. - avoiding "Not A;Brand")
          if (
            userBrowser === "unknown" &&
            nav.userAgentData.brands.length > 1
          ) {
            userBrowser = nav.userAgentData.brands[1].brand;
            browserVersion = nav.userAgentData.brands[1].version;
          }
        }
      } else {
        // Legacy approach
        const ua = navigator.userAgent;

        // Basic OS detection
        if (/Windows/.test(ua)) userOs = "Windows";
        else if (/Macintosh|Mac OS X/.test(ua)) userOs = "macOS";
        else if (/Linux/.test(ua)) userOs = "Linux";
        else if (/Android/.test(ua)) userOs = "Android";
        else if (/iPhone|iPad|iPod/.test(ua)) userOs = "iOS";

        // Browser and version detection
        const extractVersion = (regex: RegExp): string => {
          const match = ua.match(regex);
          return match && match.length > 1 ? match[1] : "unknown";
        };

        if (/Firefox\//.test(ua)) {
          userBrowser = "Firefox";
          browserVersion = extractVersion(/Firefox\/([\d.]+)/);
        } else if (/Edg\//.test(ua)) {
          userBrowser = "Edge";
          browserVersion = extractVersion(/Edg\/([\d.]+)/);
        } else if (/Chrome\//.test(ua)) {
          userBrowser = "Chrome";
          browserVersion = extractVersion(/Chrome\/([\d.]+)/);
        } else if (/Safari\//.test(ua) && !/Chrome\//.test(ua)) {
          userBrowser = "Safari";
          // Safari version is typically in Version/ not Safari/
          browserVersion = extractVersion(/Version\/([\d.]+)/);
        } else if (/OPR\//.test(ua)) {
          userBrowser = "Opera";
          browserVersion = extractVersion(/OPR\/([\d.]+)/);
        }
      }

      // Format browser with version
      const formattedBrowser = `${userBrowser} ${browserVersion}`;

      await apiClient.post("/admin/events", {
        host: window.location.host,
        sessionId: this.sessionId,
        userBrowser: formattedBrowser,
        userOs,
        events,
        reason,
      });

      devLog("event flush", reason, formattedBrowser, userOs, events.length);

      // Start new timeout for any new events
      this.startFlushTimeout();
    } catch (error) {
      console.error("Failed to send analytics batch:", error);
      // On error, put events back in queue
      this.eventQueue.unshift(...events);
    }
  }

  // Make flush accessible to LogProvider
  static getInstance() {
    if (!this.instance) {
      this.instance = new AnalyticsBatcher();
    }
    return this.instance;
  }

  private static instance: AnalyticsBatcher;
}

export const queueEvent = (event: Omit<EventRecord, "sessionId" | "ts">) => {
  const analyticsBatcher = AnalyticsBatcher.getInstance();
  analyticsBatcher.queueEvent(event);
  devLog("trackEvent", event);
};

export const saveEventLog = (reason: string) => {
  const analyticsBatcher = AnalyticsBatcher.getInstance();
  analyticsBatcher.flush(reason);
};

export const getSessionId = () => {
  return AnalyticsBatcher.getInstance().sessionId;
};

function generateFallbackSessionId(): string {
  const now = new Date();
  const datePart = now
    .toISOString()
    .slice(0, 13) // Get yyyy-MM-ddTHH
    .replace(/[-T]/g, ""); // Convert to yyyyMMddHH
  const uuid = crypto.randomUUID().replace(/-/g, "");

  return `${datePart}-${uuid}`;
}
