import { trackEvent } from "../analytics/analytics";
import apiClient, { apiUrl } from "../../services/apiClient";
import { getAuthToken } from "../accounts/auth.service";
import { journeyManager } from "../guide/journeyManager";

export const getFileBlob = async (url: string) => {
  const response = await fetch(url);
  return await response.blob();
};

export const getDataBlob = async (url: string, data) => {
  const response = await apiClient.post(url, data, {
    //responseType: "blob",
  });
  return response.data as Blob;
};

export const fetchFileType = async (url: string, name: string) => {
  const e = name.lastIndexOf(".");
  if (e > 0) return name.substring(e + 1).toUpperCase();
  const blob = await getFileBlob(url);
  const type = blob?.type?.toUpperCase() ?? "";
  const i = type.lastIndexOf("/");
  return i > 0 ? type.substring(i + 1) : type;
};

export const downloadFile = async (url: string, name: string, data?) => {
  const blob = data ? await getDataBlob(url, data) : await getFileBlob(url);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = URL.createObjectURL(blob);
  link.download = name;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);
};

export const uploadFile = (
  service: "image" | "csv",
  file,
  path: string,
  options,
  onload: (request) => void,
  onprogress?,
  abort?
) =>
  new Promise((_resolve, reject) => {
    trackEvent({
      category: service,
      action: "upload",
      label: path,
      feature: true,
      assets: service == "image" ? ["space"] : undefined,
    });
    journeyManager.updateJourneyProgress("discovery", "image_upload");

    // create a formdata object to send to the server
    const formData = new FormData();
    formData.append("file", file, path);

    if (options) {
      for (const key in options) {
        formData.append(key, options[key]);
      }
    }

    const serviceUrl =
      service === "image" ? "/images/upload" : "/data/csv-import";

    // create a request object
    const request = new XMLHttpRequest();
    request.open("POST", apiUrl + serviceUrl);
    request.setRequestHeader("Authorization", `Bearer ${getAuthToken()}`);

    // show progress in interface
    request.upload.onprogress = onprogress;

    // catch errors
    request.onerror = () => reject("oh no something went wrong!");
    request.ontimeout = () => reject("oh no request timed out!");

    // handle success state
    request.onload = () => onload(request);

    // start uploading the image
    request.send(formData);

    return {
      abort: () => {
        // This function is entered if the user has tapped the cancel button
        request.abort();

        // Let FilePond know the request has been cancelled
        abort?.();
      },
    };
  });
