import { useCallback, useMemo } from "react";
import { useAtom } from "jotai";

import { getImageUrl, BlobImage, ROOT_FOLDER, FileUpload } from "./image";
import { uploadFile } from "../data/file-util";
import { addImageToDb } from "./image.service";
import { imageDbAtom } from "./image.state";
import { openDefaultEditor, createDefaultImageWriter } from "@pqina/pintura";
import "@pqina/pintura/pintura.css";
import { trackEvent } from "../analytics/analytics";

export const imageSortBy = [
  { n: "Modified", v: "modified", o: -1 },
  { n: "Name", v: "name", o: 1 },
  { n: "Size", v: "size", o: -1 },
];

export const useImages = () => {
  const [imageDb, setImageDb] = useAtom(imageDbAtom);

  const folders = useMemo(() => {
    const result = Array.from(imageDb.folderMap.keys())
      .filter((f) => f !== ROOT_FOLDER)
      .sort((a, b) => (a === ROOT_FOLDER ? -1 : a > b ? 1 : b > a ? -1 : 0));
    result.unshift(ROOT_FOLDER);
    return result;
  }, [imageDb.folderMap]);

  const openImageEditor = (
    path: string,
    process?: (path: string, url: string) => void
  ) => {
    const image = imageDb.imageMap.get(path);
    if (image) {
      trackEvent({
        category: "image",
        action: "edit",
        label: path,
        feature: true,
      });

      const editor = openDefaultEditor({
        src: getImageUrl(imageDb.storageUrl, path),
        imageWriter: createDefaultImageWriter({
          store: (state, options, onprogress) =>
            getImageStore(state, options, onprogress, image),
        }),
      });
      editor.locale.labelButtonExport = "Save Image";
      editor.on("process", (d) => {
        const db = addImageToDb(imageDb, image.path, d.dest.size);
        setImageDb(db);
        process?.(path, getImageUrl(db.storageUrl, path));
      });
    }
  };

  const getImageStore = (state, _options, onprogress, image: BlobImage) =>
    new Promise((resolve, reject) => {
      const { dest } = state;
      const onload = (request) => {
        if (request.status >= 200 && request.status < 300) {
          // store request in state so it can be accessed by other processes
          state.store = request;
          resolve(state);
        } else {
          reject("oh no something went wrong!");
        }
      };

      return uploadFile("image", dest, image.path, null, onload, onprogress);
    });

  const openClientImageEditor = useCallback(
    (fileData: FileUpload, onUpdate: (newFile: File) => void) => {
      const editor = openDefaultEditor({
        src: fileData.preview,
        imageWriter: createDefaultImageWriter({
          targetSize: {
            width: 2048,
            height: 2048,
            fit: "contain",
          },
        }),
      });

      editor.locale.labelButtonExport = "Save Image";

      // Handle the processed image
      editor.on("process", ({ dest }) => {
        const newFile = new File([dest], fileData.file.name, {
          type: dest.type,
        });

        onUpdate(newFile);

        // Clean up old preview URL
        if (fileData.preview) {
          URL.revokeObjectURL(fileData.preview);
        }
      });
    },
    []
  );

  return {
    openImageEditor,
    folders,
    openClientImageEditor,
  };
};
