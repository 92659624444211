import { useCallback, useRef, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSnackbar } from "notistack";
import { useAuth } from "../../features/accounts/useAuth";
import { useAtom } from "jotai";
import { sessionAtom } from "../../features/accounts/account.state";
import {
  createAccount,
  switchAccount,
} from "../../features/accounts/auth.service";
import { logError } from "../../services/logging";
import { ProcessButton } from "../../components/buttons/ProcessButton";
import SmartImage from "../../components/SmartImage";

interface ProfileProps {
  openNavBar: boolean;
}

const Profile: React.FC<ProfileProps> = ({ openNavBar }: ProfileProps) => {
  const navigate = useNavigate();
  const { doLogout } = useAuth();

  const [session] = useAtom(sessionAtom);

  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isSwitchOpen, setSwitchOpen] = useState<boolean>(false);
  const [isCreateAccount, setCreateAccount] = useState<boolean>(false);
  const [newAccountName, setNewAccountName] = useState<string>("");
  const [createError, setCreateError] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);

  const ref = useRef(null);

  const handleLogout = async (): Promise<void> => {
    try {
      setOpen(false);
      doLogout();
      navigate("/login");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  const handleSwitchAccount = useCallback(
    async (id: number) => {
      try {
        const success = await switchAccount(id);

        if (success) {
          window.location.reload();
          return;
        }
      } catch (err) {
        logError(err);
      }

      enqueueSnackbar("Unable to switch account", {
        variant: "error",
      });
    },
    [enqueueSnackbar]
  );

  const createNewAccount = useCallback(async () => {
    try {
      setCreateError("");
      setProcessing(true);
      const error = await createAccount(newAccountName);
      setProcessing(false);
      if (!error) {
        setCreateAccount(false);
        window.location.reload();
        return;
      } else {
        setCreateError(error);
      }
    } catch (err) {
      logError(err);
      setProcessing(false);
    }

    enqueueSnackbar("Unable to create account", {
      variant: "error",
    });
  }, [enqueueSnackbar, newAccountName]);

  return (
    <Box
      sx={{
        "& .logo": {
          cursor: "pointer",
          maxWidth: "100%",
          maxHeight: 64,
        },
      }}
    >
      {openNavBar && (
        <>
          <Box display="flex" justifyContent="center">
            <SmartImage
              to="/account"
              src={
                session.account.logo
                  ? session.account.config.imageStorageUrl +
                    "/" +
                    session.account.logo
                  : undefined
              }
              alt={session.account.businessName}
              imageClassName="logo"
              avatarSx={{
                width: 64,
                height: 64,
                backgroundColor: session.account.brandColor,
                fontSize: "1.5rem",
              }}
              title="Go to account settings"
            />
          </Box>
        </>
      )}
      <Box mt={2} textAlign="center">
        <Link
          component={ButtonBase}
          onClick={() => setOpen(true)}
          ref={ref}
          variant="h6"
          color="textPrimary"
          underline="none"
          sx={{ width: "100%" }}
        >
          {openNavBar ? (
            <>
              <Box
                sx={{
                  width: "calc(100% - 50px)",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                title={session?.account?.businessName}
              >
                {session?.account?.businessName}
              </Box>

              <ArrowDropDownIcon />
            </>
          ) : (
            <Avatar
              alt={session.account.businessName}
              src={
                session.account.logo
                  ? session.account.config.imageStorageUrl +
                    "/" +
                    session.account.logo
                  : null
              }
              sx={{
                cursor: "pointer",
                width: 32,
                height: 32,
                backgroundColor: session.account.brandColor,
              }}
            />
          )}
        </Link>
      </Box>
      <Menu
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        anchorEl={ref.current}
        open={isOpen}
        sx={{
          width: 200,
        }}
      >
        <MenuItem
          component={NavLink}
          to="/account"
          onClick={() => setOpen(false)}
        >
          Manage account
        </MenuItem>
        {session.user.accounts.length > 1 && (
          <MenuItem
            onClick={() => {
              setSwitchOpen(true);
              setOpen(false);
            }}
          >
            Switch account...
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setCreateAccount(true);
            setOpen(false);
          }}
        >
          Create account...
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <Dialog open={isSwitchOpen} onClose={() => setSwitchOpen(false)}>
        <DialogTitle>Switch account</DialogTitle>
        <DialogContent>
          <DialogContentText>Select account to log in</DialogContentText>
          <List>
            {session.user.accounts
              .filter((a) => a.id !== session.account.id)
              .map((a) => (
                <ListItem key={a.id} disablePadding>
                  <ListItemButton onClick={() => handleSwitchAccount(a.id)}>
                    <ListItemText primary={a.businessName} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSwitchOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isCreateAccount} onClose={() => setCreateAccount(false)}>
        <DialogTitle>Create new account</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Create another account linked to your email
          </DialogContentText>
          {createError && <Alert severity="error">{createError}</Alert>}
          <Box>
            <TextField
              label="New account business name"
              fullWidth
              margin="normal"
              variant="outlined"
              value={newAccountName}
              onChange={(e) => setNewAccountName(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateAccount(false)}>Cancel</Button>
          <ProcessButton
            onClick={createNewAccount}
            label="Create"
            color="primary"
            variant="contained"
            disabled={!newAccountName}
            processing={processing}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Profile;
