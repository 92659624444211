import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Breakpoint,
  Menu,
  MenuItem,
} from "@mui/material";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { useAtom } from "jotai";
import { EmptyState } from "../../../components/EmptyState";
import { FixedPanel } from "../../../components/FixedPanel";
import { useDialogs } from "../../../contexts/useDialogs";
import {
  ProductContainer,
  getProductContainerState,
  CatalogProductSource,
  initProductContainerCollection,
  ALL_PRODUCTS,
  INDIVIDUAL_PRODUCTS,
  CATALOG_PRODUCTS,
} from "../../../features/catalogs/catalog.products";
import { CatalogItemRef } from "../../../features/catalogs/catalogs";
import {
  ProductDb,
  compareProductFields,
} from "../../../features/products/product";
import { ProductFilter } from "../../../features/products/product-func";
import { productDbAtom } from "../../../features/products/product.state";
import { CollectionSelect } from "../collections/CollectionSelect";
import { ProductFilterBox } from "./ProductFilterBox";
import { ProductList } from "./ProductList";
import { EmptyProductDb } from "../EmptyProductDb";
import { ImportExportCsv } from "../import/csv/ImportExportCsv";
import { sessionAtom } from "../../../features/accounts/account.state";
import { appFeatures } from "../../../features/guide/features";

type ProductPickerProps = {
  name: string;
  container: ProductContainer;
  setContainer: (c: ProductContainer, refresh?: boolean) => void;
  containerId?: string;
  catalogItems?: CatalogItemRef[];
  productDb: ProductDb;
  breakpoint?: Breakpoint;
  onSample?: () => void;
  onImport?: () => void;
};

export const ProductPicker = ({
  name,
  container,
  setContainer,
  containerId,
  catalogItems,
  breakpoint,
  onSample,
  onImport,
}: //productDb,
ProductPickerProps) => {
  const [session] = useAtom(sessionAtom);
  const [productDb] = useAtom(productDbAtom);

  const [filter1, setFilter1] = useState<ProductFilter>({});
  const [filter2, setFilter2] = useState<ProductFilter>({});

  const { productModalApi } = useDialogs();

  const productDbRef = useRef(productDb);
  const state = getProductContainerState(container);
  const [openCsvImport, setOpenCsvImport] = useState(false);

  const [orderEl, setOrderEl] = useState<null | HTMLElement>(null);

  const addProducts = useCallback(
    (ids: string[]) => {
      container.products = [...(container.products ?? []), ...ids];
      setContainer({ ...container });
    },
    [container, setContainer]
  );

  const removeProducts = useCallback(
    (ids: string[]) => {
      container.products =
        container.products?.filter((id) => !ids.includes(id)) ?? [];
      setContainer({ ...container });
    },
    [container, setContainer]
  );

  const changeCollection = useCallback(
    (collectionId?: string, productSource?: CatalogProductSource) => {
      const g = { ...container, collectionId, productSource };
      initProductContainerCollection(productDbRef.current, g, catalogItems);
      setContainer(g);
    },
    [catalogItems, container, setContainer]
  );

  useEffect(() => {
    productDbRef.current = productDb;
  }, [productDb]);

  const selectedProductsFields = useMemo(() => {
    const fields = new Set<string>();
    container.products?.forEach((id) => {
      const p = productDb.productMap.get(id);
      if (p) {
        p.fieldMap.forEach((_, k) => fields.add(k));
      }
    });
    return Array.from(fields).sort();
  }, [container.products, productDb.productMap]);

  const reorderListByField = useCallback(
    (field: string) => {
      container.products = container.products?.sort((a, b) =>
        compareProductFields(
          field,
          session.account.config.culture,
          productDb.productMap.get(a),
          productDb.productMap.get(b)
        )
      );
      setContainer({ ...container, products: [...container.products] });
      setOrderEl(null);
    },
    [
      container,
      productDb.productMap,
      session.account.config.culture,
      setContainer,
    ]
  );

  const reverseList = useCallback(() => {
    container.products = container.products?.reverse();
    setContainer({ ...container, products: [...container.products] });
    setOrderEl(null);
  }, [container, setContainer]);

  const isEmptyDb = productDb.productMap.size === 0;

  return (
    <Box sx={{ height: "100%" }}>
      {isEmptyDb && (
        <Box sx={{ height: "100%", p: 1 }}>
          <EmptyProductDb
            onAdd={() => productModalApi.openEditProduct({})}
            onCsvImport={() => {
              setOpenCsvImport(true);
            }}
            onSample={onSample}
          />
        </Box>
      )}
      {!isEmptyDb && (
        <>
          {state === "products" && (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Box sx={{ flex: 1, maxWidth: "50%" }}>
                <Box
                  sx={{
                    p: 0,
                    //height: { xs: undefined, lg: 64 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: 1,
                  }}
                >
                  <Box>
                    {container.products?.length > 0 && (
                      <>
                        <Button
                          variant="text"
                          size="small"
                          title="remove all products & reset"
                          onClick={() =>
                            setContainer({
                              ...container,
                              collectionId: null,
                              productSource: null,
                              products: [],
                            })
                          }
                        >
                          Reset
                        </Button>
                        <Button
                          variant="text"
                          size="small"
                          title="re-order products by field"
                          onClick={(e) => setOrderEl(e.currentTarget)}
                        >
                          Re-Order
                        </Button>
                        <Menu
                          anchorEl={orderEl}
                          open={!!orderEl}
                          onClose={() => setOrderEl(null)}
                        >
                          {selectedProductsFields.map((field) => (
                            <MenuItem
                              key={field}
                              onClick={() => reorderListByField(field)}
                            >
                              {field}
                            </MenuItem>
                          ))}
                        </Menu>
                        <Button
                          variant="text"
                          size="small"
                          title="reverse list"
                          onClick={() => reverseList()}
                        >
                          Reverse
                        </Button>
                      </>
                    )}
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <ProductFilterBox
                      filter={filter1}
                      setFilter={setFilter1}
                      //sx={{ height: 64 }}
                      noAdd
                    />
                  </Box>
                </Box>
                <FixedPanel
                  overflow="auto"
                  container={containerId}
                  margin={8}
                  breakpoint={breakpoint}
                >
                  <ProductList
                    productIds={container.products ?? []}
                    actions={["remove", "sort"]}
                    onAction={(a, ids) => {
                      if (a === "remove") {
                        removeProducts(ids);
                      } else if (a === "sort") {
                        container.products = ids;
                        setContainer({ ...container });
                      }
                    }}
                    fixed={true}
                    emptyState={
                      <Box sx={{ textAlign: "center" }}>
                        <Typography variant="h6" mb={2}>
                          {container.products?.length > 0
                            ? `0 / ${container.products.length} filtered`
                            : "No products selected"}
                        </Typography>
                        <Typography variant="body1">
                          Select products from the right panel &rarr;
                        </Typography>
                      </Box>
                    }
                    filter={filter1}
                  />
                </FixedPanel>
              </Box>

              <Box sx={{ flex: 1, maxWidth: "50%" }}>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", px: 1 }}
                >
                  <ProductFilterBox
                    filter={filter2}
                    setFilter={setFilter2}
                    //sx={{ height: 64 }}
                  />
                </Box>
                <FixedPanel
                  overflow="auto"
                  container={containerId}
                  margin={8}
                  breakpoint={breakpoint}
                >
                  <ProductList
                    excludeIds={container.products}
                    actions={["add"]}
                    onAction={(_, ids) => addProducts(ids)}
                    filter={filter2}
                    fields={["Modified"]}
                  />
                </FixedPanel>
              </Box>
            </Box>
          )}
          {(state === "unset" || state === "collection") && (
            <FixedPanel overflow="auto" container={containerId}>
              {state === "collection" && (
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Typography variant="h6">
                    {container.collectionId === ALL_PRODUCTS ? (
                      <span>All products</span>
                    ) : container.productSource === CATALOG_PRODUCTS ? (
                      <span>Catalog products</span>
                    ) : (
                      <span>
                        Collection{" "}
                        <b>{container.collection?.fullName ?? "Unknown"}</b>
                      </span>
                    )}
                  </Typography>

                  <Typography variant="caption" mb={2}>
                    {container.previewProductIds?.length} products
                    {container.inStock && <b> (in stock)</b>}
                  </Typography>

                  <Box sx={{ display: "flex", gap: 1 }}>
                    {container.collection && (
                      <Button
                        variant="outlined"
                        onClick={() =>
                          productModalApi.openCollectionEditor({
                            collectionId: container.collection.group.id,
                            onChange: (id) =>
                              setTimeout(() => changeCollection(id)),
                          })
                        }
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={() =>
                        setContainer({
                          ...container,
                          collectionId: null,
                          productSource: null,
                        })
                      }
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
              )}
              {state === "unset" && (
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <EmptyState
                    label={`How to add products to this ${name}?`}
                    btnLabel="Select products individually"
                    imageSrc="/images/open-box.png"
                    size="medium"
                    startIcon={<TouchAppIcon />}
                    onAdd={() => {
                      changeCollection(INDIVIDUAL_PRODUCTS);
                    }}
                    helpUrl={appFeatures.products.helpUrl}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                        mt: 4,
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() =>
                          changeCollection(ALL_PRODUCTS, ALL_PRODUCTS)
                        }
                      >
                        Use all my products
                      </Button>

                      {catalogItems && (
                        <Button
                          variant="outlined"
                          onClick={() => changeCollection(null, "catalog")}
                        >
                          Use catalog products
                        </Button>
                      )}

                      {onSample && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => onSample()}
                        >
                          Load sample products
                        </Button>
                      )}

                      <CollectionSelect
                        sx={{ minWidth: 150 }}
                        collectionId={filter1.collectionId}
                        onChange={(collectionId) => {
                          changeCollection(collectionId);
                        }}
                        defaultLabel="Use a product collection"
                      />

                      <FormControlLabel
                        sx={{ display: "flex", justifyContent: "center" }}
                        control={
                          <Switch
                            checked={container.inStock}
                            onChange={(e) =>
                              setContainer({
                                ...container,
                                inStock: e.target.checked,
                              })
                            }
                          />
                        }
                        label="In Stock products only"
                      />
                    </Box>
                  </EmptyState>
                </Box>
              )}
            </FixedPanel>
          )}
        </>
      )}

      <ImportExportCsv
        openCsvImport={openCsvImport}
        onCloseCsvImport={() => {
          setOpenCsvImport(false);
        }}
        onImport={onImport}
      />
    </Box>
  );
};
