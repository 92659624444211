import { ReactNode } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography, Button } from "@mui/material";
import { UiSize } from "../ui/styles";
import HelpButton from "./buttons/HelpButton";

type EmptyStateProps = {
  label?: string;
  btnLabel?: string;
  imageSrc?: string;
  onAdd?: () => void;
  children?: ReactNode;
  size?: UiSize;
  startIcon?: ReactNode;
  helpUrl?: string;
  hint?: string;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
  label,
  btnLabel,
  imageSrc,
  onAdd,
  children,
  size,
  startIcon,
  helpUrl,
  hint,
}: EmptyStateProps) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        {imageSrc && (
          <img
            src={imageSrc}
            alt={label}
            style={{ maxWidth: 64, opacity: 0.7 }}
          />
        )}
        <Typography variant="h6" sx={{ my: 3, p: 1 }}>
          {label}
        </Typography>
        {onAdd && (
          <Button
            color="primary"
            size={size ?? "small"}
            startIcon={startIcon ?? <AddIcon />}
            onClick={onAdd}
            variant="contained"
          >
            {btnLabel}
          </Button>
        )}
        {children}
        {helpUrl && (
          <HelpButton helpUrl={helpUrl} sx={{ ml: 1 }} color="primary.main" />
        )}{" "}
        {hint && (
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption">{hint}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
