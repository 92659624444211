import { useState, useEffect, useCallback, useRef } from "react";
import {
  saveEventLog,
  getSessionId,
} from "../features/analytics/analyticsBatcher";
import {
  PageLocation,
  buildLocationUrl,
  logPageView,
} from "../services/logging";
import { LogProviderProps, LogContext } from "./log.context";

export const LogProvider: React.FC<LogProviderProps> = ({
  children,
}: LogProviderProps) => {
  const [, setUrl] = useState("");
  const lastFlushTime = useRef<number>(Date.now());
  const flushTimeoutRef = useRef<number | null>(null);
  const MIN_FLUSH_INTERVAL = 60000; // Minimum 1 minute between visibility flushes

  const handleFlush = useCallback((reason: string) => {
    // Clear any pending flush timeout
    if (flushTimeoutRef.current !== null) {
      window.clearTimeout(flushTimeoutRef.current);
      flushTimeoutRef.current = null;
    }

    // Perform the flush
    saveEventLog(reason);
    lastFlushTime.current = Date.now();
  }, []);

  useEffect(() => {
    // Always flush when the page is about to unload
    const handleUnload = () => {
      handleFlush("beforeunload");
    };

    // Only flush on visibility change if it's been a while since our last flush
    const handleVisibilityChange = () => {
      if (document.visibilityState !== "visible") {
        const timeSinceLastFlush = Date.now() - lastFlushTime.current;

        if (timeSinceLastFlush >= MIN_FLUSH_INTERVAL) {
          // If it's been long enough, flush immediately
          handleFlush("visibilitychange");
        } else if (flushTimeoutRef.current === null) {
          // Otherwise, schedule a delayed flush to avoid excessive API calls
          // This ensures we'll eventually flush even if the user keeps switching tabs rapidly
          flushTimeoutRef.current = window.setTimeout(() => {
            handleFlush("delayed_visibilitychange");
            flushTimeoutRef.current = null;
          }, MIN_FLUSH_INTERVAL - timeSinceLastFlush);
        }
        // If there's already a timeout, we leave it as is
      }
    };

    // Set up a periodic flush for long-running sessions
    const intervalId = window.setInterval(() => {
      handleFlush("interval");
    }, 15 * 60 * 1000); // Every 15 minutes

    window.addEventListener("beforeunload", handleUnload);
    window.document.addEventListener(
      "visibilitychange",
      handleVisibilityChange
    );

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.document.removeEventListener(
        "visibilitychange",
        handleVisibilityChange
      );
      window.clearInterval(intervalId);

      if (flushTimeoutRef.current !== null) {
        window.clearTimeout(flushTimeoutRef.current);
      }

      handleUnload();
    };
  }, [handleFlush]);

  const logPage = useCallback((location: PageLocation) => {
    const u = buildLocationUrl(location);
    setUrl((eu) => {
      if (eu !== u) {
        logPageView(u);
      }
      return u;
    });
  }, []);

  useEffect(() => {
    logPage(window.location);
  }, [logPage]);

  return (
    <LogContext.Provider value={{ logPage, sessionId: getSessionId() }}>
      {children}
    </LogContext.Provider>
  );
};
