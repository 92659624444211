import { CatalogBuilder } from "../catalogs/catalog.builder";
import {
  ALL_PRODUCTS,
  CATALOG_PRODUCTS,
  INDIVIDUAL_PRODUCTS,
} from "../catalogs/catalog.products";
import {
  ProductRef,
  getCollectionProductIds,
  ProductGroupRef,
  CategoryRef,
  CollectionRef,
  ProductVariant,
} from "../products/product";
import {
  CompareFunction,
  EvalContentArgs,
  ValueProvider,
  ValueProviderSource,
} from "./content.eval.types";
import { ContentQuery } from "./content.query";

const queryBlocks = [
  "categories",
  "collections",
  "products1",
  "products2",
  "products",
  "variants",
  "options",
  "if",
];

export class ContentEvaluator {
  builder: CatalogBuilder;
  query: ContentQuery;

  static extVersion = "";
  static extProducts: ProductRef[] = [];
  static productBlockCache: {
    [key: string]: { content: string; version: number };
  } = {};

  constructor(builder: CatalogBuilder) {
    this.builder = builder;
    this.query = new ContentQuery(builder, this);
  }

  getContent(
    text: string,
    product?: ProductRef,
    args?: EvalContentArgs
  ): string {
    args = args || {};
    this.loadExtProducts(args);
    return this.evalContentInternal(
      text,
      args,
      product
        ? {
            type: "product",
            source: product,
            id: product.product.id,
          }
        : null
    );
  }

  evalTextContent(value: string, args: EvalContentArgs) {
    const opts = args.context || {};
    let calls = opts.calls || 1;
    const content =
      calls < 100
        ? this.evalContentInternal(
            value,
            this.query.extendContext(args, { calls: ++calls }),
            opts.provider
          )
        : value;
    args.context.calls--;
    return content;
  }

  private evalContentInternal(
    text: string,
    srcArgs: EvalContentArgs,
    provider?: ValueProvider
  ) {
    if (!text) return "";

    const fieldRe = /\{\{([^}]+)\}\}/gi;

    const args = { ...srcArgs, providers: [...(srcArgs.providers || [])] };

    if (provider) this.query.setProvider(args, provider.type, provider);

    const product = provider?.type === "product" ? provider.source : null;
    const group =
      provider?.type === "category" || provider?.type === "collection"
        ? provider.source
        : null;
    const variant = provider?.type === "variant" ? provider.source : null;
    const option = provider?.type === "option" ? provider.source : null;

    const compare: CompareFunction<ValueProviderSource> = option
      ? this.query.compareOption
      : variant
      ? this.query.compareVariant
      : group
      ? this.query.compareGroup
      : this.query.compareProduct;

    let processed = true;
    while (processed) {
      const r = this.processBlocks(text, args, compare, provider);
      text = r.text;
      processed = r.processed;
    }
    let result;
    if (group) {
      result = text.replace(fieldRe, (_match, name) => {
        return this.query.getGroupValue(group, name, args);
      });
    } else if (variant) {
      result = text.replace(fieldRe, (_match, name) => {
        return this.query.getVariantValue(variant, name, args);
      });
    } else if (option) {
      result = text.replace(fieldRe, (_match, name) => {
        return this.query.getOptionValue(option, name, args);
      });
    } else {
      result = text.replace(fieldRe, (_match, name) => {
        const c = this.query.getProductValue(product, name, args);
        return c;
      });
    }

    //if (provider) this.query.setProvider(args, provider.type, null);

    return result;
  }

  private addCollectionItems(
    collectionId: string,
    products: ProductRef[],
    ignoreIds: { [id: string]: boolean },
    inStock: boolean
  ) {
    const c = this.builder.data.productDb.collectionMap.get(collectionId);
    if (c) {
      const ids = getCollectionProductIds(
        this.builder.data.productDb,
        c,
        inStock
      );
      ids.forEach((id) => {
        if (!ignoreIds[id]) {
          const p = this.builder.data.productDb.productMap.get(id);
          products.push(p);
          ignoreIds[p.product.id] = true;
        }
      });
    }
  }

  private loadExtProducts(args: EvalContentArgs): ProductRef[] {
    const oldVersion = ContentEvaluator.extVersion;
    ContentEvaluator.extVersion = args.extension ? args.extension.version : "";
    if (
      args.extension &&
      args.extension.type === "productlist" &&
      args.extension.productSource !== ALL_PRODUCTS
    ) {
      if (ContentEvaluator.extVersion === oldVersion)
        return ContentEvaluator.extProducts;

      ContentEvaluator.extProducts = [];
      const loaded = {};
      if (args.extension.productSource === CATALOG_PRODUCTS) {
        if (this.builder?.items) {
          this.builder.items.forEach((i) => {
            if (
              i.info &&
              i.info.product &&
              !loaded[i.info.product.product.id]
            ) {
              ContentEvaluator.extProducts.push(i.info.product);
              loaded[i.info.product.product.id] = true;
            }
            if (
              i.di.PageType === "collection" &&
              i.info.config &&
              i.info.config.collectionId
            ) {
              this.addCollectionItems(
                i.info.config.collectionId,
                ContentEvaluator.extProducts,
                loaded,
                args.extension.inStock
              );
            }
          });
        }
      } else if (
        args.extension.collectionId &&
        args.extension.collectionId !== INDIVIDUAL_PRODUCTS
      ) {
        this.addCollectionItems(
          args.extension.collectionId,
          ContentEvaluator.extProducts,
          loaded,
          args.extension.inStock
        );
      } else if (args.extension.products?.length > 0) {
        const products = args.extension.products
          .map((id) => {
            return this.builder.data.productDb.productMap.get(id);
          })
          .filter((p) => p);
        ContentEvaluator.extProducts.push(...products);
      }
    } else {
      ContentEvaluator.extProducts = null;
    }
    const inStock = args.extension?.inStock;

    const products =
      ContentEvaluator.extProducts ||
      Array.from(this.builder.data.productDb.productMap.values());

    this.builder.data.productDb.categoryMap.forEach((c) => {
      if (!ContentEvaluator.extProducts && (!inStock || c.extInStock)) {
        c.extProductCount = inStock ? c.extProductCount : -1;
      } else {
        c.extProductCount = products.reduce((count, p) => {
          return p.category &&
            (p.category.group.id === c.group.id ||
              (p.category.parent &&
                p.category.parent.group.id === c.group.id)) &&
            (!inStock || p.product.inStock)
            ? count + 1
            : count;
        }, 0);
      }
      c.extInStock = !ContentEvaluator.extProducts && inStock;
    });

    this.builder.data.productDb.collectionMap.forEach((c) => {
      if (!ContentEvaluator.extProducts && (!inStock || c.extInStock)) {
        c.extProductCount = inStock ? c.extProductCount : -1;
      } else {
        c.extProductCount = products.reduce((count, p) => {
          return p.collections?.some((col) => {
            return col.group.id === c.group.id;
          }) &&
            (!inStock || p.product.inStock)
            ? count + 1
            : count;
        }, 0);
      }
      c.extInStock = !ContentEvaluator.extProducts && inStock;
    });
    return ContentEvaluator.extProducts;
  }

  private setIterator(
    args: EvalContentArgs,
    list: ValueProviderSource[],
    i: number
  ) {
    args.isFirst = i === 0;
    args.isLast = i === list.length - 1;
    args.altRow = i % 2 === 1;
  }

  private processBlockArgs(
    tagArgs: string,
    process: (field: string, op: string, value: string) => boolean
  ) {
    if (tagArgs) {
      tagArgs = tagArgs
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&nbsp;/g, " ");
    }
    let matches;
    const blockArgRe =
      /\s*:?\s*([!]?(?:\w|\s|\.)+)([=<>~^]{0,2})\s*([^:]*)\s*/gi;
    while ((matches = blockArgRe.exec(tagArgs))) {
      const field = matches[1].toLowerCase().trim();
      const op = matches[2] || "";
      const value = matches[3] ? matches[3].trim() : "";
      const r = process(field, op, value);
      if (r === false) return false;
    }
    return true;
  }

  private processListBlock<T extends ValueProviderSource>(
    tagArgs: string,
    list: T[],
    getValue: (src: T, value: string) => string,
    compareValue: CompareFunction<T>,
    args: EvalContentArgs
  ) {
    this.processBlockArgs(tagArgs, (field, op, value) => {
      if (field === "orderby") {
        if (op === "=" || op === "<" || op === ">") {
          const asc = op === "=" || op === "<";
          list.sort((p1, p2) => {
            const v1 = getValue(p1, value);
            const v2 = getValue(p2, value);
            return v1 === v2
              ? 0
              : (v1 < v2 && asc) || (v1 > v2 && !asc)
              ? -1
              : 1;
          });
        }
      } else if (field === "limitby") {
        const l = +value;
        if (op === "=" && l > 0) {
          list = list.splice(0, l);
        }
      } else if (field === "skipby") {
        const n = +value;
        if (op === "=" && n > 0) {
          list = list.splice(n);
        }
      } else if (field === "unique") {
        if (op === "=" && value) {
          const unique = [];
          const values = [];
          list.forEach((p) => {
            const v = getValue(p, value);
            if (v && unique.indexOf(v) < 0) {
              values.push(p);
              unique.push(v);
            }
          });
          list = values;
        }
      } else {
        list = list.filter((p) => {
          return compareValue(p, field, op, value, args);
        });
      }
      return undefined;
    });
    return list;
  }

  private static greCache = {};
  private getGroupRegEx(groupName: string) {
    if (!ContentEvaluator.greCache[groupName]) {
      const re =
        "\\{\\{" +
        groupName +
        "([^}]*)\\}\\}([\\s\\S]*?)\\{\\{\\/" +
        groupName +
        "\\}\\}";
      ContentEvaluator.greCache[groupName] = new RegExp(re, "i");
    }
    return ContentEvaluator.greCache[groupName];
  }

  getGroupBlockIndex(text: string, groupName: string) {
    if (!text || text.indexOf(groupName) === -1) return -1;

    const re = this.getGroupRegEx(groupName);
    const m = re.exec(text);

    return m ? m.index : -1;
  }

  private processGroupBlock(
    text: string,
    args: EvalContentArgs,
    groupName: "categories" | "collections"
  ) {
    const re = this.getGroupRegEx(groupName);
    return text.replace(re, (_match, tagArgs, blockContent) => {
      if (!blockContent) return "";

      let groups: ProductGroupRef[] =
        groupName === "collections"
          ? Array.from(this.builder.data.productDb.collectionMap.values())
          : Array.from(this.builder.data.productDb.categoryMap.values());

      if (!groups.length) return "";

      groups = groups.sortList((g) => g.group.name);

      groups = this.processListBlock(
        tagArgs,
        groups,
        (c, value) => {
          return this.query.getGroupValue(c, value, args);
        },
        (c, field, op, value) => {
          return this.query.compareGroup(c, field, op, value, args);
        },
        args
      );

      let content = "";
      for (let i = 0; i < groups.length; i++) {
        this.setIterator(args, groups, i);
        content += this.evalContentInternal(
          blockContent,
          args,
          groupName === "categories"
            ? {
                type: "category",
                source: groups[i] as CategoryRef,
                id: groups[i].group.id,
              }
            : {
                type: "collection",
                source: groups[i] as CollectionRef,
                id: groups[i].group.id,
              }
        );
      }
      return content;
    });
  }

  private processCategoriesBlock(text: string, args: EvalContentArgs) {
    return this.processGroupBlock(text, args, "categories");
  }

  private processCollectionsBlock(text: string, args: EvalContentArgs) {
    return this.processGroupBlock(text, args, "collections");
  }

  private processVariantsBlock(text: string, args: EvalContentArgs) {
    return text.replace(
      /\{\{variants([^}]*)\}\}([\s\S]*?)\{\{\/variants\}\}/i,
      (_match, tagArgs, blockContent) => {
        if (!blockContent || !this.query.getProvider("product", args))
          return "";

        let variants = [
          ...(this.query.getProvider<ProductRef>("product", args).product
            ?.variants || []),
        ];

        if (!variants.length) return "";

        variants = this.processListBlock(
          tagArgs,
          variants,
          (v, value) => {
            return this.query.getVariantValue(v, value, args, true, true);
          },
          (v, field, op, value) => {
            return this.query.compareVariant(v, field, op, value, args);
          },
          args
        );

        let content = "";
        for (let i = 0; i < variants.length; i++) {
          this.setIterator(args, variants, i);
          content += this.evalContentInternal(blockContent, args, {
            type: "variant",
            source: variants[i],
            id: "" + variants[i].id,
          });
        }
        return content;
      }
    );
  }

  private processOptionsBlock(text: string, args: EvalContentArgs) {
    return text.replace(
      /\{\{options([^}]*)\}\}([\s\S]*?)\{\{\/options\}\}/i,
      (_match, tagArgs, blockContent) => {
        if (!blockContent) return "";

        let options = Array.from(
          this.builder.data.productDb.optionMap.values()
        );
        if (this.query.getProvider("variant", args)) {
          if (this.query.getProvider("product", args)) {
            options = [];
            this.query
              .getProvider<ProductRef>("product", args)
              .category.options.forEach((co) => {
                const opt = this.query
                  .getProvider<ProductVariant>("variant", args)
                  .options.find((vo) => {
                    return vo.name === co.name;
                  });
                options.push({
                  ...co,
                  value: opt ? opt.value : "",
                });
              });
          } else {
            options = this.query.getProvider<ProductVariant>(
              "variant",
              args
            ).options;
          }
        } else if (this.query.getProvider("product", args)) {
          options = this.query.getProvider<ProductRef>("product", args).category
            .options;
        } else if (this.query.getProvider("category", args)) {
          options = this.query.getProvider<CategoryRef>(
            "category",
            args
          ).options;
        }

        if (!options.length) return "";

        options = this.processListBlock(
          tagArgs,
          options,
          (o, value) => {
            return this.query.getOptionValue(o, value, args, true);
          },
          (o, field, op, value) => {
            return this.query.compareOption(o, field, op, value, args);
          },
          args
        );

        let content = "";
        for (let i = 0; i < options.length; i++) {
          this.setIterator(args, options, i);
          content += this.evalContentInternal(blockContent, args, {
            type: "option",
            source: options[i],
            id: "" + options[i].optionId,
          });
        }
        return content;
      }
    );
  }

  private processProductsBlock(
    text: string,
    args: EvalContentArgs,
    alias?: string
  ) {
    const pname = "products" + (alias || "");
    const re =
      "\\{\\{" +
      pname +
      "(?!(1|2))([^}]*)\\}\\}([\\s\\S]*?)\\{\\{\\/" +
      pname +
      "\\}\\}";
    return text.replace(
      new RegExp(re, "i"),
      (_match, _index, tagArgs, blockContent) => {
        if (!blockContent) return "";

        let pid = "";
        if (args.providers) {
          pid = args.providers.reduce((s, p) => {
            return s + ":" + p.id;
          }, "");
        }
        const cacheKey =
          tagArgs +
          pid +
          ":" +
          blockContent +
          ":" +
          ContentEvaluator.extVersion +
          ":" +
          (alias || "");
        const bc = ContentEvaluator.productBlockCache[cacheKey];
        if (bc && this.builder.data.productDb.version === bc.version) {
          return bc.content;
        }

        let products: ProductRef[] = [];

        if (!ContentEvaluator.extProducts || alias) {
          if (!this.query.getProvider("collection", args)) {
            products = Array.from(
              this.builder.data.productDb.productMap.values()
            );
            products.sort((p1, p2) => {
              return p1.product.name < p2.product.name
                ? -1
                : p1.product.name > p2.product.name
                ? 1
                : 0;
            });
          }
        } else if (ContentEvaluator.extProducts) {
          products = ContentEvaluator.extProducts;
        }

        if (!products.length) return "";

        const categoryId =
          !alias && this.query.getProvider("category", args)
            ? this.query.getProvider<CategoryRef>("category", args).group.id
            : undefined;
        if (categoryId) {
          products = products.filter((p) => {
            return p.category.group.id === categoryId;
          });
        }

        if (!alias && this.query.getProvider("collection", args)) {
          const collectionId = this.query.getProvider<CollectionRef>(
            "collection",
            args
          ).group.id;
          const c = this.builder.data.productDb.collectionMap.get(collectionId);
          if (!c) return "";
          const cp = [];
          c.group.items.forEach((id) => {
            const p = this.builder.data.productDb.productMap.get(id);
            if (p && (!categoryId || p.category.group.id === categoryId)) {
              cp.push(p);
            }
          });
          products = cp;
        }

        const pArgs = Object.assign({}, args);
        pArgs.alias = alias;
        if (args.providers) {
          pArgs.providers = args.providers.slice();
        }

        products = this.processListBlock(
          tagArgs,
          products,
          (p, value) => {
            return this.query.getFieldValue(p, value, args);
          },
          (p, field, op, value) => {
            return this.query.compareProduct(p, field, op, value, pArgs);
          },
          args
        );

        let content = "";
        for (let i = 0; i < products.length; i++) {
          this.setIterator(pArgs, products, i);
          content += this.evalContentInternal(blockContent, pArgs, {
            source: products[i],
            type: "product",
            id: products[i].product.id,
          });
        }
        ContentEvaluator.productBlockCache[cacheKey] = {
          version: this.builder.data.productDb.version,
          content: content,
        };

        return content;
      }
    );
  }

  private processIfBlock(
    text: string,
    args: EvalContentArgs,
    compare: CompareFunction<ValueProviderSource>,
    provider: ValueProvider
  ) {
    return text.replace(
      /\{\{if([^}]*)\}\}([\s\S]*?)\{\{\/if\}\}/i,
      (_match, tagArgs, blockContent) => {
        if (!blockContent) return "";
        let result = "";
        let success = undefined;
        this.processBlockArgs(tagArgs, (field, op, value) => {
          if (field === "variants") {
            let variantSuccess = false;
            const p = (this.query.getProvider("product", args) ||
              provider) as ProductRef;
            if (p) {
              const vIndex = tagArgs.toLowerCase().indexOf("variants");
              const n = tagArgs.indexOf(":", vIndex);
              if (n > 0) {
                tagArgs = tagArgs.substr(n + 1);
                const variants = p.product?.variants || [];
                for (let i = 0; i < variants.length && !variantSuccess; i++) {
                  variantSuccess = this.processBlockArgs(
                    tagArgs,
                    (field, op, value) => {
                      const vs = this.query.compareVariant(
                        variants[i],
                        field,
                        op,
                        value,
                        args
                      );
                      return vs;
                    }
                  );
                }
              }
            }
            success = variantSuccess && success !== false;
            return false;
          } else {
            if (!provider?.source) return false;
            success = compare.call(
              this.query,
              provider.source,
              field,
              op,
              value,
              args
            );
          }
          return !!success;
        });
        const elseIndex = blockContent.toLowerCase().indexOf("{{else}}");
        let content = null;
        if (elseIndex >= 0) {
          content = success
            ? blockContent.substr(0, elseIndex)
            : blockContent.substr(elseIndex + "{{else}}".length);
        } else if (success) {
          content = blockContent;
        }
        if (content) {
          result = this.evalContentInternal(content, args, provider);
        }
        return result;
      }
    );
  }

  private processBlocks(
    text: string,
    args: EvalContentArgs,
    compare: CompareFunction<ValueProviderSource>,
    provider: ValueProvider
  ) {
    const blocks = [];
    const t = text.toLocaleLowerCase();
    for (let i = 0; i < queryBlocks.length; i++) {
      const index = this.getGroupBlockIndex(t, queryBlocks[i]);
      if (index >= 0) blocks.push({ n: queryBlocks[i], i: index });
    }
    blocks.sort((a, b) => {
      return a.i < b.i ? -1 : 1;
    });

    const oldText = text;

    if (blocks.length) {
      switch (blocks[0].n) {
        case "categories":
          text = this.processCategoriesBlock(text, args);
          break;
        case "collections":
          text = this.processCollectionsBlock(text, args);
          break;
        case "products":
          text = this.processProductsBlock(text, args);
          break;
        case "products1":
          text = this.processProductsBlock(text, args, "1");
          break;
        case "products2":
          text = this.processProductsBlock(text, args, "2");
          break;
        case "variants":
          text = this.processVariantsBlock(text, args);
          break;
        case "options":
          text = this.processOptionsBlock(text, args);
          break;
        case "if":
          text = this.processIfBlock(text, args, compare, provider);
          break;
      }
      return { text: text, processed: oldText !== text };
    }

    return { text: text };
  }
}
