import { DesignerBoardApi, DesignerChange } from "../designer.board.api";

//export type DesignMode = "pages" | "grids";

export enum BoardTabIndex {
  None = -1,
  Start,
  Layouts,
  Grids,
  Elements,
  Products,
  Images,
  Layers,
  Settings,
  Clipboard,
}

export enum LayoutTabIndex {
  None = -1,
  Design,
  Templates,
  Layers,
  Test,
  Settings,
  Clipboard,
}

export enum TemplateTabIndex {
  None = -1,
  Products,
  Design,
  Fields,
  Layers,
  Test,
  Settings,
  Clipboard,
}

export type DesignerTabIndex =
  | BoardTabIndex
  | LayoutTabIndex
  | TemplateTabIndex;

export type DesignSidebarProps = {
  api: DesignerBoardApi;
  onChange: (change: DesignerChange) => void;
  openGridEditor: () => void;
  onTogglePanel: () => void;
};

export type SettingsTabArg = "page" | "catalog" | "catalog:headfoot";
