import { positionElement } from "../../containers/designer/designer.func";
import { DefaultImageField, EMPTY_GUID } from "../data/data-types";
import { setRotationStyle, imagePositions } from "../design/design.types";
import { templateGridSize, gridSize } from "../design/designer";
import { CatalogBuilder } from "./catalog.builder";
import { InitItemArgs, syncItemAttrs, CreateItemAttrs } from "./catalog.design";
import {
  getProductField,
  getImageSrc,
  getProductTemplate,
} from "./catalog.func";
import { getBgStyle, initBg } from "./catalog.style";
import {
  updateTemplateItemsStyle,
  createNewTemplate,
  initTemplateItems,
} from "./catalog.template";
import {
  CatalogItemRef,
  CatalogItem,
  CatalogPageRef,
  ItemSinkType,
} from "./catalogs";
import { initProductListExt } from "./product-list.ext";
import { Template } from "../templates/templates";
import { getDimensions } from "./catalog.page";

export const createCatalogTemplateItem = (
  builder: CatalogBuilder,
  productId: string,
  template: Template,
  x: number,
  y: number,
  w: number,
  h: number
) => {
  const scale = templateGridSize / gridSize;
  const item = {
    di: {
      Type: "template",
      ItemId: productId,
      TemplateId: template.id,
      x: x * scale,
      y: y * scale,
      w: (w || template.width) * scale,
      h: (h || template.height) * scale,
    },
  } as CatalogItemRef;
  initItem(builder, item);
  return item;
};

export const updateItemStyle = (
  builder: CatalogBuilder,
  item: CatalogItemRef,
  page?: CatalogPageRef
) => {
  const d = getDimensions(builder, page);

  if (item.type === "page") {
    item.style = {
      backgroundColor: item.di.Color || builder.catalog.bgColor,
    };
    return;
  }

  item.style = {
    left: Math.round(item.di.x * d.scaleX),
    top: Math.round(item.di.y * d.scaleY),
    width: Math.round(item.di.w * d.scaleX),
    height: Math.round(item.di.h * d.scaleY),
    zIndex: item.di.Position || 1,
    ...getBgStyle(item, 0, builder),
  };

  setRotationStyle(item);

  if (item.type === "icon") {
    item.style["color"] = item.di.Color;
    item.style["font-size"] =
      Math.min(item.style.width, item.style.height) + "px";
  }

  updateTemplateItemsStyle(builder, {
    parent: item,
  });
};

export const initItem = (
  builder: CatalogBuilder,
  item: CatalogItemRef,
  args?: InitItemArgs
) => {
  if (item.type === "page") return item;

  item.info = item.info || { isEmpty: true };

  item.info.extension = initProductListExt(item.di.Extension);

  if (
    item.di.ItemId &&
    (!item.info.product ||
      item.info.product.product.id !== item.di.ItemId ||
      !args?.soft)
  ) {
    item.info.product = builder.data.productDb.productMap.get(item.di.ItemId);
  }
  if (!item.di.ItemId) {
    item.info.product = undefined;
  }

  item.info = { ...item.info, title: item.di.Title || "" };

  if (item.type === "text") {
    if (!args?.soft || !item.info.content) {
      item.info.content = builder.evaluator.getContent(
        item.di.Body,
        item.info.product,
        {
          extension: item.info.extension,
        }
      );
    }
    item.info.isEmpty = !item.info.content?.length;
  }
  if (item.type === "icon") {
    item.info.isEmpty = false;
  }
  if (item.type === "image") {
    item.info.timestamp = new Date().getTime();
    const src = item.info.product
      ? getProductField(item.info.product, item.di.Body)?.value || ""
      : item.di.Body || "";
    item.info.isEmpty = !src || !!item.di.Sink;
    item.info.src = !item.info.isEmpty
      ? getImageSrc(
          builder.data.config.imageStorageUrl,
          src,
          false,
          item.version
        )
      : "";
    item.info.title += (item.info.title ? " - " : "") + src;
    item.info.path = src;
    item.info.imageField = item.info.product
      ? item.di.Body || DefaultImageField
      : null;
  }
  if (item.type === "template") {
    if (item.di.TemplateId !== item.info.template?.template.id || args?.force) {
      item.info.template =
        item.di.TemplateId === EMPTY_GUID
          ? createNewTemplate(builder)
          : getProductTemplate(
              builder.data.templates,
              item.info.product,
              item.di.TemplateId
            );
    }

    initTemplateItems(builder, {
      ...args,
      parent: item,
    });

    if (item.di.Sink) {
      const tname = item.info.template?.template.name ?? "No Card";
      item.info.title = (item.info.title || "Card") + " [" + tname + "]";
    }

    item.info.isEmpty = item.info.templateItems?.length === 0;
  }

  if (!item.di.x) item.di.x = 0;
  if (!item.di.y) item.di.y = 0;
  if (item.di.h < builder.board.min) item.di.h = builder.board.min;
  if (item.di.w < builder.board.min) item.di.w = builder.board.min;

  if (item.type === "bg") {
    item.info.isEmpty = false;
  }

  if (!args.initial) updateItemStyle(builder, item, args?.page);

  return syncItemAttrs(item);
};

export const newItem = (ci: CatalogItem) => {
  return syncItemAttrs({
    di: ci,
    info: {},
    asset: {},
    id: crypto.randomUUID(),
    version: 0,
    meta: "catalog",
  }) as CatalogItemRef;
};

export const createPageItem = (
  builder: CatalogBuilder,
  page: CatalogPageRef,
  attrs: CreateItemAttrs,
  standalone?: boolean
) => {
  const d = getDimensions(builder, page);

  const { type, content, x, y, pos } = attrs;

  const w = attrs.w || builder.board.newSize;
  const h = attrs.h || builder.board.newSize;

  const emptyProductElement = !attrs.productId && attrs.subtype === "product";

  const ci: CatalogItem = {
    Type: type,
    ImgPosition: type === "image" ? imagePositions[0] : undefined,
    Body: content,
    z: 1,
    ItemId: attrs.productId,
    TemplateId: attrs.templateId,
    Color: attrs.color,
    Extension: attrs.ext,
    exp: attrs.exp,
    scale: attrs.scale,
    Sink: !content || emptyProductElement ? (type as ItemSinkType) : undefined,
    w: Math.floor(
      (w > builder.board.min ? w : builder.board.min) / (d.scaleX ?? 1)
    ),
    h: Math.floor(
      (h > builder.board.min ? h : builder.board.min) / (d.scaleY ?? 1)
    ),
    ...attrs.clone,
    PageId: page.pageId,
  };

  assertSink(ci);

  if (pos === "none") {
    positionElement(ci, page);
  } else {
    ci.x = Math.floor((x >= 0 ? x : 0) / (d.scaleX ?? 1));
    ci.y = Math.floor((y >= 0 ? y : 0) / (d.scaleY ?? 1));
  }

  if (ci.w + ci.x > templateGridSize) ci.x = templateGridSize - ci.w;
  if (ci.h + ci.y > templateGridSize) ci.y = templateGridSize - ci.h;

  if (attrs.type === "bg" && !attrs.clone) {
    initBg(ci);
  }

  const item = newItem(ci);

  if (!standalone) {
    builder.items.push(item);
    page.items.push(item);
    orderItem(item, page, true);
  }

  initItem(builder, item, { page });
  return item;
};

export const createTemplatePageItem = (
  builder: CatalogBuilder,
  templateId: string,
  productId: string,
  w: number,
  h: number,
  standalone: boolean
) => {
  if (!standalone) {
    builder.pages = [];
    builder.items = [];
  }
  const page = builder.addPage(0, standalone);
  const item = createPageItem(
    builder,
    page,
    {
      isNew: true,
      x: 0,
      y: 0,
      w,
      h,
      templateId,
      productId,
      type: "template",
      content: "",
    },
    standalone
  );
  return item;
};

export const orderItem = (
  item: CatalogItemRef,
  page: CatalogPageRef,
  isFront?: boolean,
  withStyle?: boolean
) => {
  if (!item) return;

  page.items.sort((a, b) => (a.di.Position < b.di.Position ? -1 : 1));
  let z = isFront ? 1 : 2;
  page.items
    .filter((i) => i !== item)
    .forEach((i) => {
      if (i.di.Type !== "page") {
        i.di.Position = z++;
        if (withStyle) i.style = { ...i.style, zIndex: i.di.Position };
      }
    });
  item.di.Position = isFront ? z : 1;
  if (withStyle) item.style = { ...item.style, zIndex: isFront ? z : 1 };
};

export const removePageItem = (
  builder: CatalogBuilder,
  item: CatalogItemRef
) => {
  if (!item) return;
  builder.items = builder.items.filter((i) => i.id !== item.id);
  const page = builder.pages[item.di.PageId];
  if (page) {
    page.items = page.items.filter((i) => i.id !== item.id);
  }
  const viewPage = builder.structure.viewPages.find((p) => p.id === page.id);
  if (viewPage) {
    viewPage.items = viewPage.items.filter((i) => i.id !== item.id);
  }
};

export const assertSink = (ci: CatalogItem, partial?: Partial<CatalogItem>) => {
  if (
    ci.Sink &&
    (ci.Type === "bg" ||
      ci.Type === "icon" ||
      (ci.Type === "text" && ci.ItemId) ||
      (ci.Type === "image" && (ci.ItemId || partial?.Body)) ||
      (ci.Type === "template" && ci.ItemId && ci.TemplateId))
  ) {
    ci.Sink = undefined;
  }
};
