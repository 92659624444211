import { useCallback } from "react";
import {
  addProductVariant,
  Product,
  ProductVariant,
  refreshVariantField,
  VariantFieldUpdate,
} from "../../../features/products/product";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";
import { sessionAtom } from "../../../features/accounts/account.state";
import {
  deleteDbProducts,
  updateVariantField,
} from "../../../features/products/product.service";
import { productDbAtom } from "../../../features/products/product.state";
import { journeyManager } from "../../../features/guide/journeyManager";

export const useVariantGrid = () => {
  const [productDb, setProductDb] = useAtom(productDbAtom);
  const [session] = useAtom(sessionAtom);
  const { enqueueSnackbar } = useSnackbar();

  const deleteVariant = useCallback(
    async (v: ProductVariant) => {
      const p = productDb.productMap.get(v.productId);
      if (
        !productDb.productMap
          .get(v.productId)
          ?.product.variants?.some((pv) => pv.id === v.id)
      )
        return;

      try {
        const result = await deleteDbProducts("variants", [v.id]);
        if (result.success) {
          const variants = p.product.variants.filter((pv) => pv.id !== v.id);
          p.product = { ...p.product, variants };
          setProductDb(() => ({
            ...productDb,
            version: productDb.version + 1,
          }));
          enqueueSnackbar("Deleted variant for " + p.product.name, {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Error deleting variant for " + p.product.name, {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar(
          "Error deleting variant for " +
            p.product.name +
            ": " +
            (error as Error)?.message,
          {
            variant: "error",
          }
        );
      }
    },
    [enqueueSnackbar, productDb, setProductDb]
  );

  const getVariantUpdates = useCallback(
    (newRow, oldRow) => {
      const p = newRow.$product as Product;
      if (!p) return [];

      const updates: VariantFieldUpdate[] = Object.keys(newRow)
        .filter((k) => newRow[k] !== oldRow[k])
        .map((k) => ({
          field: k,
          value: newRow[k],
          id: "" + newRow.id,
          productId: p.id,
          optionId: productDb.categoryMap
            .get(p.categoryId)
            ?.options.find((o) => o.name === k)?.optionId,
        }));
      return updates;
    },
    [productDb.categoryMap]
  );

  const updateVariantFields = useCallback(
    async (p: Product, updates: VariantFieldUpdate[]) => {
      try {
        const result = await updateVariantField(updates);

        if (result.processed.length > 0) {
          const db = { ...productDb, version: productDb.version + 1 };

          updates
            .filter((u) => result.processed.some((id) => +id === +u.id))
            .forEach((u) => {
              refreshVariantField(
                p,
                +u.id,
                u.field,
                u.optionId,
                u.value,
                productDb.storageUrl,
                result.inserted[u.id],
                db.productMap.get(p.id)
              );
            });
          setProductDb(db);

          enqueueSnackbar("Saved variant for " + p.name, {
            variant: "success",
          });
        }
        if (!result.success) {
          enqueueSnackbar(
            (result.failed?.length > 1
              ? result.failed?.length + " errors"
              : "Error") +
              " saving variant for " +
              p.name +
              ": " +
              result.message,
            {
              variant: "error",
            }
          );
          throw new Error("update error");
        }
      } catch (error) {
        enqueueSnackbar("Error while saving variant", {
          variant: "error",
        });
        throw error;
      }
    },
    [enqueueSnackbar, productDb, setProductDb]
  );

  const processVariantUpdate = useCallback(
    async (newRow, oldRow) => {
      const updates = getVariantUpdates(newRow, oldRow);
      if (!updates.length) return newRow;
      await updateVariantFields(newRow.$product, updates);
      return newRow;
    },
    [getVariantUpdates, updateVariantFields]
  );

  const addVariant = useCallback(
    (product: Product, onAdd?: (variant?: ProductVariant) => void) => {
      if (product.variants?.length >= session.account.limits.variants) {
        enqueueSnackbar(
          `Product exceeds ${session.account.limits.variants} variants limit for ${session.account.planName} plan.`,
          {
            variant: "error",
          }
        );
        onAdd?.();
        return;
      }
      const v = addProductVariant(product);

      //setProductDb({ ...productDb, version: productDb.version + 1 });
      onAdd?.(v);
      journeyManager.updateJourneyProgress("product_setup", "add_variants");
    },
    [enqueueSnackbar, session.account.limits.variants, session.account.planName]
  );

  return {
    deleteVariant,
    processVariantUpdate,
    getVariantUpdates,
    addVariant,
    updateVariantFields,
  };
};
