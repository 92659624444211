import { SxProps } from "@mui/material";
import { SelectFromList, SelectSize } from "../../../components/SelectFromList";
import { useDialogs } from "../../../contexts/useDialogs";
import { useProducts } from "../../../features/products/product-func";

export const CollectionSelect = ({
  collectionId,
  onChange,
  defaultLabel,
  sx,
  startMenu,
  size,
  disabled,
}: {
  collectionId: string;
  onChange: (collectionId: string) => void;
  defaultLabel?: string;
  sx?: SxProps;
  startMenu?: { value: string; name: string }[];
  size?: SelectSize;
  disabled?: boolean;
}) => {
  const { productModalApi } = useDialogs();
  const { collectionNames } = useProducts();

  return (
    <SelectFromList
      entries={collectionNames.map((c) => ({
        value: c.value,
        name: `${c.name} (${c.count})`,
        level: c.level,
      }))}
      value={collectionId ?? ""}
      setValue={(v) => {
        if (v === "new") {
          productModalApi.openCollectionAttrs({
            withItems: true,
            onChange,
          });
        } else {
          onChange(v);
        }
      }}
      defaultLabel={defaultLabel}
      label={collectionId ? "Collection" : ""}
      size={size || "small"}
      sx={sx}
      startMenu={startMenu}
      endMenu={[{ value: "new", name: "+ Create new collection" }]}
      disabled={disabled}
    />
  );
};
