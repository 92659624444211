import {
  MarkExtension,
  ApplySchemaAttributes,
  MarkSpecOverride,
  MarkExtensionSpec,
  isElementDomNode,
  omitExtraAttributes,
  ExtensionTag,
  CommandFunction,
  command,
} from "remirror";

interface CustomSpanAttributes {
  class?: string;
  dataAttributes?: Record<string, string>;
}

/**
 * An extension that preserves span elements with custom attributes
 * like class names and data-* attributes.
 */
export class CustomSpanExtension extends MarkExtension {
  get name() {
    return "customSpan" as const;
  }

  createTags() {
    return [ExtensionTag.FormattingMark];
  }

  createMarkSpec(
    extra: ApplySchemaAttributes,
    override: MarkSpecOverride
  ): MarkExtensionSpec {
    return {
      ...override,
      attrs: {
        ...extra.defaults(),
        class: { default: null },
        dataAttributes: { default: null },
      },
      parseDOM: [
        {
          tag: "span",
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return false;
            }

            // Skip parsing if it's a span created by other extensions like fontSize
            if (
              dom.style.fontSize ||
              dom.hasAttribute("data-font-size") ||
              dom.style.color ||
              dom.hasAttribute("data-text-color") ||
              dom.style.fontFamily ||
              dom.hasAttribute("data-font-family") ||
              dom.style.backgroundColor // For your TextBgColorExtension
            ) {
              return false;
            }

            const className = dom.className;

            // Only preserve spans with classes or data attributes
            if (!className && !this.hasDataAttributes(dom)) {
              return false;
            }

            // Collect all data-* attributes
            const dataAttributes: Record<string, string> = {};
            for (const attr of dom.attributes) {
              if (attr.name.startsWith("data-")) {
                dataAttributes[attr.name] = attr.value;
              }
            }

            return {
              ...extra.parse(dom),
              class: className || null,
              dataAttributes:
                Object.keys(dataAttributes).length > 0 ? dataAttributes : null,
            };
          },
          priority: 51, // Higher priority than default extensions
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (mark) => {
        const {
          class: className,
          dataAttributes,
          ...other
        } = omitExtraAttributes<CustomSpanAttributes>(mark.attrs, extra);

        // Create a proper attrs object with the correct type
        const attrs: Record<string, string | null | undefined> = {
          ...extra.dom(mark),
          ...other,
        };

        // Add class if present
        if (className) {
          attrs.class = className;
        }

        // Add data attributes if present
        if (dataAttributes) {
          Object.entries(dataAttributes).forEach(([key, value]) => {
            attrs[key] = value;
          });
        }

        return ["span", attrs, 0];
      },
    };
  }

  private hasDataAttributes(element: HTMLElement): boolean {
    for (const attr of element.attributes) {
      if (attr.name.startsWith("data-")) {
        return true;
      }
    }
    return false;
  }

  /**
   * Add a custom span with the specified class and data attributes
   */
  @command()
  addCustomSpan(attributes: {
    class?: string;
    dataAttributes?: Record<string, string>;
  }): CommandFunction {
    return this.store.commands.applyMark.original(this.type, attributes);
  }
}

declare global {
  namespace Remirror {
    interface AllExtensions {
      customSpan: CustomSpanExtension;
    }
  }
}
