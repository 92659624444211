import { SelectChangeEvent, Select } from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-pro";

interface SelectEditCellParams extends GridRenderCellParams {
  entries: { name; value }[];
}

export const SelectEditCell = (props: SelectEditCellParams) => {
  const { id, value, field, entries } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
    if (apiRef.current?.getRowMode(id) === "edit") {
      apiRef.current.stopRowEditMode({ id });
    } else {
      apiRef.current.stopCellEditMode({ id, field });
    }
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ height: 1, fontSize: 14 }}
      native
    >
      {entries.map((e) => (
        <option key={e.value} value={e.value}>
          {e.name}
        </option>
      ))}
    </Select>
  );
};
