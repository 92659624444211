import structuredClone from "@ungap/structured-clone";
import { setPageSize, CatalogData } from "./catalog.func";
import {
  Catalog,
  CatalogGrid,
  CatalogItem,
  CatalogItemRef,
  CatalogPageRef,
  CatalogPageType,
  CatalogSection,
  PageItemConfig,
} from "./catalogs";
import { ContentEvaluator } from "../eval/content.eval";
import { assertSink, initItem, newItem } from "./catalog.item";
import { createPage, initPage } from "./catalog.page";
import {
  BoardViewProps,
  DesignAreaProps,
  getBoardSize,
} from "../design/designer";
import { InitItemArgs } from "./catalog.design";
import { previewGrid, refreshGrids } from "./catalog.grid";
import {
  CatalogStructure,
  refreshCatalogStructure,
  setActivePageId,
} from "./catalog.structure";
import { pageSizes } from "./catalog.defs";
import {
  LayoutRef,
  getAllLayouts,
  initLayout,
  injectLayoutItems,
} from "../layouts/layouts";
import { trackEvent } from "../analytics/analytics";

type BuilderContext = {
  activePageId?: number;
  viewPageIndex?: number;
  activeGridId?: number;
  stamp?: number;
  layoutStamp?: number;
};

const sortItems = (items: CatalogItemRef[]) => {
  items.sort(function (a, b) {
    if (a.di.PageId < b.di.PageId) return -1;
    if (a.di.PageId > b.di.PageId) return 1;
    return a.di.Position < b.di.Position ? -1 : 1;
  });
};

export class CatalogBuilder {
  catalog: Catalog;
  items: CatalogItemRef[];
  pages: CatalogPageRef[];
  board: DesignAreaProps;
  context: BuilderContext;
  evaluator: ContentEvaluator;
  ready: boolean;
  initArgs: InitItemArgs;
  structure: CatalogStructure;

  grids: CatalogGrid[];
  layout: LayoutRef;
  sections: CatalogSection[];

  id: string;
  data: CatalogData;
  pageLimit: number;
  exceedLimit: boolean;

  constructor(catalog: Catalog, pageLimit = -1) {
    this.id = new Date().toISOString().slice(14, 19);

    this.catalog = catalog;

    this.context = {
      activePageId: 0,
      viewPageIndex: 0,
      layoutStamp: 0,
      stamp: 0,
    };
    this.evaluator = new ContentEvaluator(this);
    this.initArgs = {};
    this.grids = [];
    this.sections = [];
    this.structure = { entries: [], viewPages: [], productPages: {} };

    this.setBoard();
    this.createPages(catalog.catalogItems);
    this.pageLimit = pageLimit;
  }

  checkPageLimit() {
    if (this.pageLimit > 0 && this.pages.length > this.pageLimit) {
      this.exceedLimit = true;
      return false;
    }
    return true;
  }

  // reachedPageLimit() {
  //   return (
  //     this.exceedLimit &&
  //     this.pageLimit > 0 &&
  //     this.pageLimit >= this.pages.length
  //   );
  // }

  setBoard(view?: BoardViewProps) {
    const pageSize = view?.pageSize
      ? pageSizes.find((s) => s.name === view.pageSize)
      : null;
    setPageSize(this.catalog.catalogPage, pageSize);

    this.board = getBoardSize({
      w: this.catalog.catalogPage.pageWidth || 0,
      h: this.catalog.catalogPage.pageHeight || 0,
    });
    this.board.view = view;
  }

  findPage(pageId: number) {
    return this.pages.find((p) => p.pageId === pageId);
  }

  getActivePage() {
    return this.pages[this.context.activePageId || 0] ?? this.pages[0];
  }

  getActivePageEntry() {
    return this.getPageEntry(this.context.activePageId);
  }

  getPageEntry(pageId: number) {
    return this.structure.entries.find((e) => e.range.start === pageId);
  }

  getViewPage() {
    return (
      this.structure.viewPages[this.context.viewPageIndex || 0] ??
      this.getActivePage()
    );
  }

  setActivePage(pageId: number, isView?: boolean) {
    setActivePageId(this, pageId, isView);
  }

  getItem(id: string) {
    return this.items.find((i) => i.id === id);
  }

  initItem(
    item: CatalogItemRef,
    partial?: Partial<CatalogItem>,
    args?: InitItemArgs
  ) {
    if (!item) return;

    if (partial && item?.di) {
      item.di = { ...item.di, ...partial };
      assertSink(item.di, partial);
    }
    if (item.type === "template") {
      args = { soft: true, ...args };
    }
    return initItem(this, item, {
      ...this.initArgs,
      ...args,
    });
  }

  createPages(itemJson: string) {
    const items = itemJson
      ? (JSON.parse(itemJson) as CatalogItem[]).map((i) => newItem(i))
      : [];
    sortItems(items);

    const pages: CatalogPageRef[] = items.reduce((acc, item) => {
      item.di.PageId = item.di.PageId || 0;
      const page = acc.find((p) => p.pageId === item.di.PageId);
      if (page) {
        page.items.push(item);
      } else {
        for (let i = acc.length; i < item.di.PageId; i++) {
          acc.push(createPage({ pageId: i }));
        }
        acc.push(
          createPage({
            pageId: item.di.PageId,
            items: [item],
          })
        );
      }
      return acc;
    }, [] as CatalogPageRef[]);

    this.items = items;
    this.pages = pages;

    if (pages.length === 0) {
      this.addPage(0);
    }

    this.checkPageLimit();
  }

  initPages() {
    this.pages.forEach((p) => initPage(this, p));
  }

  initItems() {
    if (!this.data) return;
    this.items.forEach((i) =>
      initItem(this, i, { ...this.initArgs, force: true, initial: true })
    );
    this.initPages();
    this.refreshGrids();
    this.refreshStructure();
    this.ready = true;
  }

  setData(data: CatalogData) {
    const v = this.data?.version ?? 0;
    this.data = data;
    this.data.version = v + 1;
    this.initItems();
  }

  addPage(
    pageId?: number,
    standalone?: boolean,
    type?: CatalogPageType,
    config?: PageItemConfig
  ) {
    if (!standalone && !this.checkPageLimit()) {
      return;
    }

    pageId = pageId !== undefined ? pageId : this.context.activePageId + 1;
    const page = createPage({ pageId });
    if (!standalone) {
      this.pages.splice(pageId, 0, page);
      //this.refreshPages();
    }
    if (this.ready || standalone)
      initPage(this, page, type, config, standalone);
    return page;
  }

  insertPage(page: CatalogPageRef, pageId?: number) {
    if (!this.checkPageLimit()) {
      return;
    }

    pageId = pageId !== undefined ? pageId : this.context.activePageId;
    page = structuredClone(page);
    page.pageId = pageId;
    page.id = crypto.randomUUID();
    this.pages.splice(pageId, 0, page);
    page.items = page.items.map((i) => {
      const ni = this.initItem(i);
      ni.id = crypto.randomUUID();
      return ni;
    });
    this.items = [...this.items, ...page.items];
    initPage(this, page);
    this.refreshPages(true);
    return page;
  }

  addLayout(
    layoutRef: LayoutRef | string,
    replace?: boolean,
    isThumb?: boolean
  ) {
    let layout: LayoutRef;
    const isId = typeof layoutRef === "string";
    if (isId) {
      const l = getAllLayouts(this.data.layoutDb).find(
        (l) => l.layout.id === layoutRef
      );
      layout = { ...l };
    } else {
      layout = layoutRef;
    }

    this.layout = layout;

    if (!layout) return;

    let pageId;
    if (replace) {
      pageId = this.context.activePageId;
      this.deletePages(pageId);
    }

    const page = this.addPage(pageId);

    if (!page) return;

    initLayout(this, layout, !isId);

    const injected = injectLayoutItems(this, page, {
      x: 0,
      y: 0,
      kw: 1,
      kh: 1,
      items: layout.items.map((i) => i.di),
    });

    if (injected > 0 && !isThumb) {
      trackEvent({
        type: "trace",
        category: "layout",
        action: "inject",
        label: `${layout.layout.name} (${layout.layout.id}) items: ${injected}, page: ${page.pageId}`,
        internal: true,
      });
    }

    return page;
  }

  deletePages(pageId: number, count = 1) {
    this.pages.splice(pageId, count);
    this.items = this.items.filter(
      (i) => i.di.PageId < pageId || i.di.PageId >= pageId + count
    );
    trackEvent({
      type: "trace",
      category: "pages",
      action: "delete",
      label: `from ${pageId} count: ${count}`,
      internal: true,
    });
  }

  refreshPages(force = false) {
    let modified = false;
    if (force) this.pages = [...this.pages];
    this.pages.forEach((page, i) => {
      if (page.pageId !== i || force) {
        page.pageId = i;
        initPage(this, page);
        modified = true;
      }
    });
    if (modified) {
      this.refreshGrids();
      this.refreshStructure();
      this.setActivePage(this.context.activePageId);
    }
    return modified;
  }

  refreshGrids() {
    this.grids = refreshGrids(this);
    this.grids
      .filter((g) => !g.preview && !g.isNew)
      .forEach((g) => previewGrid(this, g));

    if (!this.grids.find((g) => g.id === this.context.activeGridId)) {
      this.context.activeGridId = undefined;
    }

    return this.grids;
  }

  refreshStructure() {
    refreshCatalogStructure(this);
  }

  shouldReplaceLayout() {
    const p = this.getActivePage();
    return (
      (p.items.length <= 2 && p.pageItem?.di?.PageType === "page") ||
      this.context.layoutStamp === this.context.stamp
    );
  }
}

export const createBuilder = (c: Catalog, pageLimit = -1): CatalogBuilder => {
  const catalog = structuredClone(c);
  return new CatalogBuilder(catalog, pageLimit);
};
