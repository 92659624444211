import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SxProps,
  FormHelperText,
} from "@mui/material";
import {
  ListValueType,
  NameValueType,
  SelectEntryType,
  emptySelectValue,
  getSelectionEntry,
  isEmptySelectValue,
} from "./selection";
import { EMPTY_GUID } from "../features/data/data-types";

export type SelectSize = "mini" | "small" | "medium";

interface SelectFromListProps<T extends ListValueType> {
  label?: string;
  entries: SelectEntryType<T>;
  value?: T;
  setValue: (value?: T) => void;
  size?: SelectSize;
  defaultLabel?: string;
  checkIncluded?: boolean;
  sx?: SxProps;
  error?: string;
  startMenu?: NameValueType<T>[];
  endMenu?: NameValueType<T>[];
  displayEmpty?: boolean;
  helpText?: string | React.ReactNode;
  disabled?: boolean;
}

export const SelectFromList = <T extends ListValueType>({
  label,
  entries,
  value,
  setValue,
  size,
  defaultLabel,
  sx,
  startMenu,
  endMenu,
  error,
  checkIncluded,
  displayEmpty,
  helpText,
  disabled,
}: SelectFromListProps<T>) => {
  const menuItems = [...(startMenu ?? []), ...entries, ...(endMenu ?? [])] as
    | NameValueType<T>[]
    | T[];

  //value = value ?? "";
  const included =
    value !== undefined &&
    menuItems.some((e) => e.value === value || e === value);

  const isEmpty =
    value === undefined ||
    value === null ||
    value === "" ||
    value === emptySelectValue ||
    value === EMPTY_GUID;

  const en = menuItems.map((e) => e).find((e) => e === "" || e.value === "");

  const hasEmptyValue = en !== undefined;

  const sxMini =
    size === "mini"
      ? {
          ".MuiSelect-select": {
            padding: "8px 32px 8px 8px}",
            "& .MuiInputBase-root": { fontSize: 12 },
            "& .MuiFormLabel-root": { fontSize: 14, top: 2 },
            "& .MuiSvgIcon-root": { right: "5px" },
            "& legend": { fontSize: 12 },
          },
        }
      : {};

  //const showLabel = label && (value || displayEmpty);

  return (
    <FormControl
      size={size === "mini" ? "small" : size}
      sx={{ ...sx, ...sxMini }}
      error={!!error || (checkIncluded && !included)}
    >
      {label && <InputLabel id="label">{label}</InputLabel>}
      <Select
        value={isEmpty ? emptySelectValue : value}
        onChange={(e) => {
          const v = e.target.value as T;
          setValue(isEmptySelectValue(v) ? ("" as T) : v);
        }}
        label={label}
        labelId="select"
        disabled={disabled}
        //displayEmpty={!!defaultLabel || displayEmpty}
        //input={showLabel ? <OutlinedInput label={label} /> : null}
      >
        {(displayEmpty || defaultLabel) && !hasEmptyValue && (
          <MenuItem key="empty" value={emptySelectValue}>
            {defaultLabel || ""}
          </MenuItem>
        )}
        {menuItems.map((item) => {
          const entry = getSelectionEntry(item, defaultLabel);
          return (
            <MenuItem
              key={entry.value}
              value={
                isEmptySelectValue(entry.value) ? emptySelectValue : entry.value
              }
            >
              {Array.from(Array(entry.level ?? 0).keys()).map(() => "• ")}
              {entry.name}
            </MenuItem>
          );
        })}
        {!included && !isEmpty && (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        )}
      </Select>
      {(error || helpText) && (
        <FormHelperText>
          {error ? error : null}
          {helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
