import {
  Suspense,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  useActive,
  useAttrs,
  useChainedCommands,
  useCommands,
  useRemirrorContext,
} from "@remirror/react";
import { Divider, Button, Box, ToggleButton } from "@mui/material";
import { useDialogs } from "../../contexts/useDialogs";
import { IconTypes } from "../icons.types";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import {
  FontFamilyButtons,
  FontSizeButtons,
  ColorButton,
  YoutubeMenu,
} from "./components/editor-buttons";
import { LinkButton } from "./components/link-button";
import { TableButtons } from "./components/table-buttons";
import { insertFieldToEditor } from "./editor-func";
import { useHtmlEditorContext } from "./useHtmlEditorContext";
import {
  CommandMenuItem,
  CommandButtonGroup,
  ToggleBoldButton,
  ToggleItalicButton,
  DropdownButton,
  CommandButton,
  LeftAlignButton,
  CenterAlignButton,
  RightAlignButton,
  ToggleHeadingMenuItem,
  UndoButton,
  RedoButton,
} from "@remirror/react-ui";

const EditorCommandMenu = ({ name, ext }: { name: string; ext: string }) => {
  const commands = useCommands();
  const command = commands[name];
  const active = useActive()[ext]?.();
  if (!command) {
    return null;
  }
  return (
    <CommandMenuItem
      commandName={name}
      enabled={command.enabled?.()}
      active={active}
      onSelect={command}
      displayDescription={false}
    />
  );
};

export type HtmlEditorMenuProps = {
  embedded?: boolean;
  compact?: boolean;
  disableExternalEdit?: boolean;
  onExternalEdit?: (html: string) => void;
  editorId: string;
};

export const HtmlEditorMenu = ({
  embedded,
  compact: forceCompact,
  disableExternalEdit,
  onExternalEdit,
  editorId,
}: HtmlEditorMenuProps) => {
  const {
    isSourceEdit,
    setSourceEdit,
    onChange,
    setEditedHtml,
    editedHtml,
    toHtml,
    darkBkg,
    setDarkBkg,
    fields,
    fieldArgs,
  } = useHtmlEditorContext();

  const [autoNarrow, setAutoNarrow] = useState(false);
  const [autoCompact, setAutoCompact] = useState(false);

  // Use either forced compact mode from props or auto-detected compact mode
  const isCompact = forceCompact || autoCompact;
  // For non-compact mode, still use narrow layout if space is limited
  const isNarrow = !isCompact && autoNarrow;

  const { setContent, commands } = useRemirrorContext();
  const { openImageModal, openHtmlEditorModal } = useDialogs();
  const [snapHtml, setSnapHtml] = useState<string>();

  const ref = useRef<HTMLDivElement>(null);

  // Check available width to determine layout mode
  useLayoutEffect(() => {
    if (!isSourceEdit && ref.current) {
      const width = ref.current.offsetWidth;
      setAutoCompact(width < 500);
      setAutoNarrow(width < 900);
    }
  }, [isSourceEdit, ref.current?.offsetWidth]);

  const chain = useChainedCommands();

  const handleUpdate = () => {
    setContent(editedHtml);
    onChange();
    setSourceEdit(false);
  };

  const cancelUpdate = () => {
    setContent(snapHtml);
    onChange();
    setSourceEdit(false);
  };

  const handlePreviewHtml = () => {
    const h = toHtml();
    setEditedHtml(h);
    setSnapHtml(h);
    setSourceEdit(true);
  };

  const attrs = useAttrs();

  const clearMarkup = useCallback(
    () => commands.removeMark({ type: null }),
    [commands]
  );

  const handleExternalEdit = (editorId: string) => {
    const htmlString = toHtml();
    openHtmlEditorModal({
      value: htmlString,
      onChange: onExternalEdit,
      editorId: "external-" + editorId,
    });
  };

  // Render compact mode with minimal buttons
  const renderCompactMode = () => (
    <>
      <CommandButtonGroup className="btn-group">
        <ToggleBoldButton />
        <ToggleItalicButton />
        <LinkButton />
        {fields && (
          <DropdownButton
            aria-label="Expressions"
            color="secondary"
            icon="bracesLine"
          >
            {fields.map((f) => (
              <CommandMenuItem
                key={f.name}
                onSelect={() =>
                  insertFieldToEditor(f.content, fieldArgs, {
                    chain,
                    insertText: commands.insertText,
                  })
                }
                displayDescription={false}
                label={f.name}
                commandName="insertText"
                enabled={true}
              />
            ))}
          </DropdownButton>
        )}
      </CommandButtonGroup>

      <Box sx={{ flexGrow: 1 }} />

      {!disableExternalEdit && (embedded || isCompact) && onExternalEdit && (
        <Button
          size="small"
          variant="outlined"
          color="mute"
          startIcon={<IconTypes.OpenInNew />}
          onClick={() => handleExternalEdit(editorId)}
          sx={{
            color: "text.secondary",
            borderColor: "text.secondary",
            "&:hover": {
              borderColor: "text.primary",
              color: "text.primary",
            },
          }}
        >
          Full Editor
        </Button>
      )}
    </>
  );

  return (
    <Suspense>
      <div ref={ref}>
        <Box
          className="editor-toolbar"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
            minHeight: "48px",
            px: 0,
          }}
        >
          {!isSourceEdit && (
            <>
              {isCompact ? (
                renderCompactMode()
              ) : (
                <>
                  <CommandButtonGroup className="btn-group">
                    <FontFamilyButtons />
                    <FontSizeButtons />
                    <ColorButton
                      name="textColor"
                      color={attrs.textColor()?.color as string}
                      setColor={(color) => {
                        commands.setTextColor(color);
                      }}
                      icon="fontColor"
                      label="Text Color"
                    />
                    <ColorButton
                      name="bgColor"
                      color={attrs.textBgColor()?.bgColor as string}
                      setColor={(color) => {
                        commands.setTextBgColor(color);
                      }}
                      icon="markPenLine"
                      label="Text Background Color"
                    />
                  </CommandButtonGroup>

                  <CommandButtonGroup className="btn-group">
                    <ToggleBoldButton />
                    <ToggleItalicButton />
                    <DropdownButton
                      aria-label="More font styles"
                      color="secondary"
                    >
                      <EditorCommandMenu
                        name="toggleUnderline"
                        ext="underline"
                      />
                      <EditorCommandMenu name="toggleStrike" ext="strike" />
                      <EditorCommandMenu name="toggleSubscript" ext="sub" />
                      <EditorCommandMenu name="toggleSuperscript" ext="sup" />
                    </DropdownButton>
                  </CommandButtonGroup>

                  <CommandButtonGroup className="btn-group">
                    {fields && (
                      <DropdownButton
                        aria-label="Expressions"
                        color="secondary"
                        icon="bracesLine"
                      >
                        {fields.map((f) => (
                          <CommandMenuItem
                            key={f.name}
                            onSelect={() =>
                              insertFieldToEditor(f.content, fieldArgs, {
                                chain,
                                insertText: commands.insertText,
                              })
                            }
                            displayDescription={false}
                            label={f.name}
                            commandName="insertText"
                            enabled={true}
                          />
                        ))}
                      </DropdownButton>
                    )}
                    <LinkButton />
                    <CommandButton
                      commandName="insertImage"
                      active={false}
                      enabled={true}
                      icon="imageLine"
                      label="Insert image"
                      onSelect={() => {
                        openImageModal(
                          {
                            onSelect: (_p, url) => {
                              commands.insertImage({ src: url });
                            },
                            canChange: true,
                            canSelectFolder: true,
                          },
                          "select"
                        );
                      }}
                    />
                    <TableButtons />
                    <DropdownButton aria-label="More embeds" color="secondary">
                      <YoutubeMenu />
                    </DropdownButton>
                  </CommandButtonGroup>

                  <CommandButtonGroup className="btn-group">
                    {!isNarrow && (
                      <>
                        <LeftAlignButton />
                        <CenterAlignButton />
                        <RightAlignButton />
                      </>
                    )}
                    <DropdownButton
                      aria-label="More text formatting"
                      icon={isNarrow ? "paragraph" : null}
                      color="secondary"
                    >
                      {isNarrow && (
                        <EditorCommandMenu name="leftAlign" ext="node" />
                      )}
                      {isNarrow && (
                        <EditorCommandMenu name="centerAlign" ext="node" />
                      )}
                      {isNarrow && (
                        <EditorCommandMenu name="rightAlign" ext="node" />
                      )}
                      <EditorCommandMenu name="increaseIndent" ext="node" />
                      <EditorCommandMenu name="decreaseIndent" ext="node" />
                      <Divider />
                      <CommandMenuItem
                        icon={<FormatLineSpacingIcon fontSize="small" />}
                        onSelect={() => {
                          commands.setBottomPadding(0);
                        }}
                        displayDescription={false}
                        label="Condense paragraphs"
                        commandName="insertText"
                        enabled={true}
                      />
                      <Divider />
                      <EditorCommandMenu
                        name="toggleOrderedList"
                        ext="orderedList"
                      />
                      <EditorCommandMenu
                        name="toggleBulletList"
                        ext="bulletList"
                      />
                      <Divider />
                      <ToggleHeadingMenuItem
                        attrs={{ level: 1 }}
                        label="Heading 1"
                      />
                      <ToggleHeadingMenuItem
                        attrs={{ level: 2 }}
                        label="Heading 2"
                      />
                      <ToggleHeadingMenuItem
                        attrs={{ level: 3 }}
                        label="Heading 3"
                      />
                    </DropdownButton>
                  </CommandButtonGroup>
                </>
              )}
            </>
          )}

          {isSourceEdit && (
            <>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleUpdate}
              >
                Back to Editor
              </Button>
              <Button
                size="small"
                onClick={cancelUpdate}
                sx={{ ml: "32px !important;" }}
              >
                Discard changes
              </Button>
            </>
          )}

          {!isSourceEdit && !isCompact && (
            <Box
              sx={{
                flexGrow: 1,
                mr: 1,
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              <CommandButtonGroup className="btn-group">
                <CommandButton
                  commandName="removeFormat"
                  label="Clear formatting"
                  icon="formatClear"
                  enabled={true}
                  onSelect={clearMarkup}
                />
                {isNarrow && (
                  <DropdownButton aria-label="More commands" color="secondary">
                    <EditorCommandMenu name="undo" ext="strike" />
                    <EditorCommandMenu name="redo" ext="sub" />
                    {!embedded && (
                      <CommandMenuItem
                        commandName="html"
                        enabled={true}
                        onSelect={handlePreviewHtml}
                        label="Edit HTML"
                        icon="codeLine"
                      />
                    )}
                  </DropdownButton>
                )}
              </CommandButtonGroup>
              {!isNarrow && (
                <>
                  <CommandButtonGroup className="btn-group">
                    <UndoButton />
                    <RedoButton />
                  </CommandButtonGroup>
                  <CommandButtonGroup className="btn-group">
                    <ToggleButton
                      size="small"
                      value="bkg"
                      selected={darkBkg}
                      onChange={() => {
                        setDarkBkg(!darkBkg);
                      }}
                      title="Toggle dark background"
                      sx={{
                        "&.MuiToggleButton-root.Mui-selected": {
                          color: "#eee",
                          backgroundColor: "#777",
                          "&:hover": {
                            backgroundColor: "#666",
                          },
                        },
                        svg: {
                          width: 14,
                          height: 14,
                        },
                      }}
                    >
                      {darkBkg ? (
                        <IconTypes.LightMode />
                      ) : (
                        <IconTypes.DarkMode />
                      )}
                    </ToggleButton>

                    {/* {!embedded && (
                      <CommandButton
                        commandName="fullscreenEdit"
                        label={
                          fullScreen
                            ? "Exit full screen edit"
                            : "Full screen edit"
                        }
                        icon={
                          fullScreen ? "fullscreenExitLine" : "fullscreenLine"
                        }
                        enabled={true}
                        onSelect={() => setFullScreen?.(!fullScreen)}
                      />
                    )} */}
                  </CommandButtonGroup>
                  {!embedded && (
                    <CommandButtonGroup className="btn-group">
                      <CommandButton
                        commandName="html"
                        label="Edit HTML"
                        icon="codeLine"
                        enabled={true}
                        onSelect={handlePreviewHtml}
                      />
                    </CommandButtonGroup>
                  )}
                </>
              )}
              {!disableExternalEdit && onExternalEdit && (
                <CommandButton
                  commandName="externalEdit"
                  label="Edit in popup"
                  icon="fullscreenLine"
                  enabled={true}
                  onSelect={() => handleExternalEdit(editorId)}
                />
              )}
            </Box>
          )}
        </Box>
      </div>
    </Suspense>
  );
};
