import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from "react";
import { Box, Button } from "@mui/material";
import "remirror/styles/all.css";
import "./remirror.scss";
import {
  OnChangeHTML,
  Remirror,
  useChainedCommands,
  useRemirror,
  useRemirrorContext,
} from "@remirror/react";
import { HtmlEditorMenu } from "./HtmlEditorMenu";
import { HtmlEditorProps } from "./editor.context";
import { editorExtensions } from "./editor.extensions";
import { HtmlEditorRef } from "./editor-func";
import { useHtmlEditorContext } from "./useHtmlEditorContext";
import { HtmlEditorBox } from "./HtmlEditorBox";
import { HtmlEditorProvider } from "./editor.provider";
import { IconTypes } from "../icons.types";
import { useDialogs } from "../../contexts/useDialogs";

// Update HtmlEditorProps to include new options
export interface EnhancedHtmlEditorProps extends HtmlEditorProps {
  compact?: boolean;
  disableExternalEdit?: boolean;
  ultraCompact?: boolean;
}

export const InnerEditor = (props: HtmlEditorProps) => {
  const context = useHtmlEditorContext();
  return (
    <>
      <OnChangeHTML onChange={context.onChange} />
      {props.children}
    </>
  );
};

const ImperativeHandle = forwardRef(
  (_: unknown, ref: React.Ref<HtmlEditorRef>) => {
    const { setContent, manager, commands } = useRemirrorContext();

    const replaceContent = (content: string) => {
      manager.view.updateState(manager.createState({ content: content }));
    };

    const chain = useChainedCommands();

    useImperativeHandle(ref, () => ({
      setContent,
      replaceContent,
      focus: commands.focus,
      insertText: commands.insertText,
      insertParagraph: commands.insertParagraph,
      chain: chain,
    }));

    return <></>;
  }
);

export const HtmlEditorWrapperSwitch = forwardRef(
  (props: EnhancedHtmlEditorProps, ref: React.Ref<HtmlEditorRef>) => {
    if (props.value === null || props.value === undefined) {
      return <>{props.children}</>;
    }
    return <HtmlEditorWrapper {...props} ref={ref} />;
  }
);

export const HtmlEditorWrapper = forwardRef(
  (props: EnhancedHtmlEditorProps, ref: React.Ref<HtmlEditorRef>) => {
    const { manager, state } = useRemirror({
      extensions: editorExtensions,
      content: props.value,
      selection: "start",
      stringHandler: "cmCode",
      extraAttributes: [
        {
          identifiers: "nodes",
          attributes: {
            "data-expr-start": { default: null },
            "data-expr-end": { default: null },
          },
        },
        {
          identifiers: ["table", "tableRow", "tableCell", "tableHeaderCell"],
          attributes: { style: { default: null } },
        },
      ],
    });

    return (
      <Remirror manager={manager} initialContent={state}>
        <HtmlEditorProvider {...props}>
          <InnerEditor {...props} />
          <ImperativeHandle ref={ref} />
        </HtmlEditorProvider>
      </Remirror>
    );
  }
);

// Ultra-compact mode that shows just a button to open editor in popup
export const UltraCompactHtmlEditor = (props: EnhancedHtmlEditorProps) => {
  const { openHtmlEditorModal } = useDialogs();

  const handleEdit = () => {
    openHtmlEditorModal({
      value: props.value,
      onChange: props.onChange,
      fields: props.fields,
      fieldArgs: props.fieldArgs,
      editorId: `ultracompact-${props.editorId}`,
    });
  };

  // Show content preview with edit button
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        p: 1,
        position: "relative",
        minHeight: "36px",
        cursor: "pointer",
        "&:hover": {
          borderColor: "primary.main",
        },
      }}
      onClick={handleEdit}
    >
      <Box
        sx={{
          fontSize: "0.875rem",
          maxHeight: "60px",
          overflow: "hidden",
          pr: 8,
          "& p": {
            m: 0,
          },
        }}
      >
        {props.value ? (
          <div dangerouslySetInnerHTML={{ __html: props.value }} />
        ) : (
          <Box sx={{ color: "text.secondary", fontStyle: "italic" }}>
            Click to edit
          </Box>
        )}
      </Box>

      <Button
        size="small"
        variant="contained"
        color="primary"
        startIcon={<IconTypes.Edit />}
        sx={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          minWidth: 0,
          p: "4px 8px",
        }}
        onClick={handleEdit}
      >
        Edit
      </Button>
    </Box>
  );
};

export const HtmlEditor = (props: EnhancedHtmlEditorProps) => {
  const { compact, ultraCompact, disableExternalEdit, ...restProps } = props;

  // For ultra-compact mode, just show a button that opens the editor in a popup

  // Detect container width for auto-compact mode
  const containerRef = useRef<HTMLDivElement>(null);
  const [autoCompact, setAutoCompact] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;

    const checkWidth = () => {
      const width = containerRef.current!.offsetWidth;
      setAutoCompact(width < 500);
    };

    checkWidth();

    // Add resize listener
    const resizeObserver = new ResizeObserver(checkWidth);
    const currentContainer = containerRef.current;
    resizeObserver.observe(currentContainer);

    return () => {
      if (currentContainer) {
        resizeObserver.unobserve(currentContainer);
      }
    };
  }, []);

  if (ultraCompact) {
    return <UltraCompactHtmlEditor {...props} />;
  }

  const useCompactMode = compact || autoCompact;

  return (
    <Box ref={containerRef} sx={{ width: "100%" }}>
      <HtmlEditorWrapper {...restProps}>
        <Box>
          <HtmlEditorMenu
            editorId={props.editorId}
            compact={useCompactMode}
            disableExternalEdit={disableExternalEdit}
            onExternalEdit={props.onChange}
          />
        </Box>

        <HtmlEditorBox
          onSourceEdit={props.onChange}
          editorId={props.editorId}
          compact={useCompactMode}
          maxHeight={props.maxHeight}
        />
      </HtmlEditorWrapper>
    </Box>
  );
};

ImperativeHandle.displayName = "ImperativeHandle";
HtmlEditorWrapper.displayName = "HtmlEditorWrapper";
HtmlEditorWrapperSwitch.displayName = "HtmlEditorWrapperSwitch";
