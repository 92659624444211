import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { Box, Divider } from "@mui/material";

import { useBoardActions } from "./useBoardActions";
import { FixedPanel, FixedPanelRef } from "../../../components/FixedPanel";
import { HtmlEditorMenu } from "../../../components/remirror/HtmlEditorMenu";
import { HtmlEditorWrapper } from "../../../components/remirror/RemirrorEditor";
import { HtmlEditorRef } from "../../../components/remirror/editor-func";
import { CatalogBuilder } from "../../../features/catalogs/catalog.builder";
import {
  DesignItemRef,
  BoundRect,
} from "../../../features/design/design.types";
import { renderPageBackground } from "../catalogs/catalog.render";
import {
  DesignerChange,
  ItemSelectionMode,
  DesignBoardActionsProps,
  DesignerBoardApi,
} from "../designer.board.api";
import { translateElementCoordinates } from "../designer.func";
import { useMovable } from "../useMovable";
import { BackgroundToolbar } from "./BackgroundToolbar";
import { DesignItemMenu } from "./DesignItemMenu";
import { NavToggle } from "./NavToggle";
import { HtmlEditorBox } from "../../../components/remirror/HtmlEditorBox";

export type DesignBoardProps = {
  onChange: (change: DesignerChange) => void;
  onSelect: (item?: DesignItemRef, mode?: ItemSelectionMode) => void;
  addHistory: (title: string, pageNum: number) => void;
  isNavOpen: boolean;
  onNavToggle: (isOpen: boolean) => void;
};

export type ExternalDesignBoardProps = {
  builder: CatalogBuilder;
} & DesignBoardProps;

type InternalDesignBoardProps = {
  boardId: string;
  toolbar: React.ReactNode | React.ReactNode[];
  boardToolbar?: React.ReactNode | React.ReactNode[];
  footerToolbar?: React.ReactNode | React.ReactNode[];
  header?: React.ReactNode;
  footer?: React.ReactNode;
  content?: React.ReactNode;
  overlays?: React.ReactNode;
  renderItem: (item: DesignItemRef) => React.ReactNode;
  zoom?: number;
  onWheel?: (deltaY: number) => void;
} & DesignBoardProps &
  DesignBoardActionsProps;

export type ToolbarMode = "page" | "item" | "html" | "product-image" | "image";

export type DesignBoardRef = {
  clearTargets: () => void;
  editorRef: HtmlEditorRef;
  api: DesignerBoardApi;
  selectBoardItem: (item: DesignItemRef, mode: ItemSelectionMode) => void;
  adjustBoard: () => void;
};

export const DesignBoard = forwardRef<DesignBoardRef, InternalDesignBoardProps>(
  (
    {
      boardId,
      onChange,
      onItemDrop,
      renderItem,
      designerBoardApi,
      boardContainer,
      pageItems,
      boardToolbar,
      footerToolbar,
      toolbar,
      header,
      footer,
      content,
      overlays,
      zoom,
      onWheel,
      isNavOpen,
      onNavToggle,
      readOnly,
    }: InternalDesignBoardProps,
    designBoardRef: React.MutableRefObject<DesignBoardRef>
  ) => {
    const editorRef = useRef<HtmlEditorRef>(null);
    const boardRef = useRef<HTMLDivElement>(null);

    const boardPanelId = `${boardId}-board-panel`;
    const boardPanel = document.getElementById(boardPanelId);
    const boardRect = boardRef.current?.getBoundingClientRect();
    const parentRect = boardPanel?.getBoundingClientRect();

    const pageZoom = zoom > 0 ? zoom / 100 : 1;

    const scrollTop = boardPanel?.scrollTop;
    const scrollLeft = boardPanel?.scrollLeft;
    const bounds: BoundRect =
      boardRect && parentRect
        ? {
            top: boardRect.top - parentRect.top + scrollTop,
            right: boardRect.right - parentRect.left + scrollLeft + 1,
            bottom: boardRect.bottom - parentRect.top + scrollTop + 1,
            left: boardRect.left - parentRect.left + scrollLeft,
            zoom: pageZoom,
          }
        : {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zoom: 1,
          };

    const { renderSelecto, renderMovable, isSelected, setIsSelected } =
      useMovable({
        api: designerBoardApi,
        boardContainer,
        onChange,
        boardElement: boardRef.current,
        bounds,
      });

    const {
      builder,
      selectItem,
      selectedItem,
      editedHtml,
      setEditedHtml,
      editedId,
      targets,
      setTargets,
      boardState,
    } = designerBoardApi;

    const activePage = builder?.getViewPage();
    const readonly = readOnly || activePage?.entry?.readOnly;

    const { selectBoardItem, dragOverElement, dragOverToggle, dropElement } =
      useBoardActions({
        designerBoardApi,
        pageItems,
        boardContainer,
        onChange,
        editorRef,
        boardRef,
        onItemDrop,
        readOnly: readonly,
        pageZoom,
      });

    const clearTargets = () => {
      setTargets([]);
      selectItem();
    };

    useImperativeHandle(
      designBoardRef,
      (): DesignBoardRef => ({
        clearTargets,
        editorRef: editorRef.current,
        api: designerBoardApi,
        selectBoardItem,
        adjustBoard,
      })
    );

    const pageFrame = {
      kw: 1 / (builder.board.view?.productsHor ?? 1),
      kh: 1 / (builder.board.view?.productsVert ?? 1),
    };

    const elemMap = useMemo(() => {
      if (boardState.version === 0) return {};

      return targets.reduce((m, t) => {
        const coords = translateElementCoordinates(t, builder);
        m[t.id] = {
          pos: `${coords.left} : ${coords.top}`,
          size: `${coords.width} x ${coords.height}`,
          info: `x: ${coords.left} y: ${coords.top} w: ${coords.width} h: ${coords.height}`,
          r: "" + coords.r,
        };
        return m;
      }, {} as Record<string, { pos: string; info: string; size: string; r: string }>);
    }, [targets, boardState.version, builder]);

    const boardWrapRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const viewRef = useRef<FixedPanelRef>(null);

    const adjustBoard = useCallback(() => {
      const wrap = boardWrapRef.current;
      const container = containerRef.current;
      if (!wrap || !container) return;
      const offsetX = (wrap.offsetWidth * pageZoom) / 2;
      wrap.style.left =
        offsetX > container.offsetWidth / 2 ? "0" : `calc(50% - ${offsetX}px)`;
      clearTargets();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageZoom]);

    useEffect(() => {
      adjustBoard();
    }, [adjustBoard]);

    useEffect(() => {
      window.addEventListener("resize", adjustBoard);
      return () => window.removeEventListener("resize", adjustBoard);
    }, [adjustBoard]);

    const handleWheel = useCallback(
      (deltaY: number) => {
        if (!onWheel) return;
        const view = viewRef.current?.panelRef.current;
        if (!view || !deltaY || view.scrollHeight > view.clientHeight) return;
        onWheel(deltaY);
      },
      [onWheel]
    );

    if (!builder?.board) return null;

    const pageHeight =
      (activePage?.pageHeight ?? builder.board.h) * pageFrame.kh;

    const headerHeight = (activePage?.headerHeight ?? 0) * pageFrame.kh;

    return (
      <Box
        className="design-board-component"
        sx={{
          "& .page-part .unstyled-text, & .remirror-editor-wrapper .remirror-editor":
            {
              color: builder.catalog.fontColor,
              fontFamily: builder.catalog.baseFont,
              "& h1, & h2, & h3, & h4, & h5, & h6": {
                color: builder.catalog.headerColor,
                fontFamily: builder.catalog.headerFont,
              },
            },
        }}
      >
        <HtmlEditorWrapper
          value={editedHtml ?? ""}
          onChange={setEditedHtml}
          ref={editorRef}
          fields={
            selectedItem
              ? designerBoardApi.getItemFields(selectedItem, true)
              : null
          }
          fieldArgs={
            selectedItem ? { product: selectedItem.info?.product } : null
          }
          editorId={boardId + "-wrapper-editor"}
        >
          <Box className="design-board-toolbar">
            <NavToggle
              isNavOpen={isNavOpen}
              onNavToggle={(isOpen) => {
                onNavToggle(isOpen);
                clearTargets();
              }}
            />
            {editedHtml !== null && (
              <Box
                sx={{
                  flexGrow: 1,
                  ml: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <HtmlEditorMenu
                  embedded={true}
                  onExternalEdit={setEditedHtml}
                  editorId={boardId + "-menu-editor"}
                />
              </Box>
            )}
            {selectedItem?.type === "bg" && (
              <BackgroundToolbar item={selectedItem} api={designerBoardApi} />
            )}
            {toolbar}
          </Box>
          <Divider />

          <Box
            sx={{
              width: "100%",
              margin: "4px auto",
              px: 1,
            }}
          >
            {boardToolbar}
          </Box>
          <FixedPanel
            overflow="auto"
            className="design-board-viewport movable-container "
            id={boardPanelId}
            onWheel={(e) => handleWheel(e.deltaY)}
            ref={viewRef}
            margin={footerToolbar ? 40 : 0}
            breakpoint="lg"
          >
            <Box
              className="design-board-container"
              ref={containerRef}
              onClick={(e) => {
                if (
                  !(
                    e.target instanceof Element &&
                    (e.target.closest(".design-board") ||
                      e.target.closest(".keep-edit"))
                  )
                ) {
                  if (!isSelected) {
                    clearTargets();
                  }
                  setIsSelected(false);
                }
              }}
            >
              <Box
                className="design-board-wrapper"
                ref={boardWrapRef}
                sx={{
                  height: builder.board.h, // + 30,
                  width: builder.board.w,
                  scale: "" + pageZoom,
                }}
              >
                <Box
                  className="design-board"
                  sx={{
                    height: builder.board.h * pageFrame.kh,
                    width: builder.board.w * pageFrame.kw,
                  }}
                >
                  {renderPageBackground(activePage)}
                  {overlays}
                  {header}
                  {content && (
                    <Box
                      className="page-part"
                      sx={{
                        height: pageHeight,
                      }}
                    >
                      {content}
                    </Box>
                  )}
                  {!content && (
                    <Box
                      sx={{
                        height: pageHeight,
                      }}
                      className="page-part page-body element-drop-target drop-container"
                      id="board-container"
                      ref={boardRef}
                      onDrop={dropElement}
                      onDragOver={dragOverElement}
                      onDragEnter={(e) => dragOverToggle(e, true)}
                      onDragLeave={(e) => dragOverToggle(e, false)}
                    >
                      {pageItems.map((item) => (
                        <Box
                          key={item.id}
                          id={item.id}
                          style={item.style}
                          className={
                            "catalog-item board-item board-item-" +
                            (item.type?.toLocaleLowerCase() ?? "none") +
                            (item.info?.extension ? " extension" : "")
                          }
                          onClick={() => {
                            if (
                              targets.length === 1 &&
                              targets[0].id === item.id
                            ) {
                              selectItem(item.id, true, false, "click");
                            }
                          }}
                        >
                          {editedId !== item.id && (
                            <>
                              {renderItem(item)}
                              {elemMap[item.id] && !!boardState.mode && (
                                <div
                                  className="item-info"
                                  title={elemMap[item.id]?.info}
                                >
                                  {boardState.mode === "resize"
                                    ? elemMap[item.id]?.size
                                    : boardState.mode == "rotate"
                                    ? elemMap[item.id]?.r
                                    : elemMap[item.id]?.pos}
                                </div>
                              )}
                            </>
                          )}
                        </Box>
                      ))}
                    </Box>
                  )}
                  {footer}
                  <Box
                    className="unmovable"
                    sx={{
                      position: "absolute",
                      display: editedHtml != null ? "block" : "none",
                      overflow: "auto",
                      ".remirror-editor": {
                        minHeight: selectedItem?.style.height ?? "auto",
                        height: selectedItem?.style.height ?? "auto",
                      },
                    }}
                    style={{
                      left: selectedItem?.style.left ?? 0,
                      top: (selectedItem?.style.top ?? 0) + headerHeight,
                      width: selectedItem?.style.width ?? 0,
                      height: selectedItem?.style.height ?? 0,
                      zIndex: 1000,
                    }}
                  >
                    <HtmlEditorBox
                      embedded={true}
                      editorId={
                        boardId +
                        "-editor-item-" +
                        (selectedItem ? selectedItem.id : "board")
                      }
                    />
                  </Box>
                </Box>
              </Box>
              {!readonly && renderMovable()}

              {!boardState.mode && (
                <DesignItemMenu
                  item={selectedItem}
                  bounds={bounds}
                  api={designerBoardApi}
                />
              )}
            </Box>
          </FixedPanel>
          {!readonly && renderSelecto()}
          {footerToolbar && (
            <Box
              sx={{
                width: "100%",
                margin: "4px auto",
                height: 32,
                px: 1,
              }}
            >
              {footerToolbar}
            </Box>
          )}
        </HtmlEditorWrapper>
      </Box>
    );
  }
);

DesignBoard.displayName = "DesignerBoard";
