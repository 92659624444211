import {
  HubConnectionBuilder,
  HubConnection,
  LogLevel,
  HubConnectionState,
  RetryContext,
} from "@microsoft/signalr";
import { getAuthToken } from "../features/accounts/auth.service";
import { devLog } from "./util";
import { JourneyProgress } from "../features/guide/journey";

export const hubUrl = import.meta.env.VITE_API_URL + "/accountHub";

class AccountHubService {
  private connection: HubConnection;
  private connectionPromise: Promise<void> | null = null;
  private journeyCallbacks: ((data: {
    journeyType: string;
    stepKey: string;
  }) => void)[] = [];
  private reconnectAttempt = 0;
  private readonly maxRetryAttempts = 3;
  private readonly initialRetryDelayMs = 5000;
  private readonly maxRetryDelayMs = 60000;
  private isTabActive = !document.hidden;
  private lastReconnectTime: number = 0;
  private readonly minReconnectIntervalMs = 30000;

  constructor() {
    this.connection = this.createConnection();
    this.setupEventHandlers();
    this.setupVisibilityHandling();
  }

  private setupVisibilityHandling() {
    document.addEventListener("visibilitychange", () => {
      this.isTabActive = !document.hidden;
    });
  }
  private createConnection(): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => getAuthToken() || "",
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
          if (retryContext.previousRetryCount >= this.maxRetryAttempts) {
            return null; // Stop retrying after max attempts
          }

          const now = Date.now();
          if (now - this.lastReconnectTime < this.minReconnectIntervalMs) {
            return null;
          }

          const delay = Math.min(
            this.initialRetryDelayMs *
              Math.pow(2, retryContext.previousRetryCount),
            this.maxRetryDelayMs
          );

          this.lastReconnectTime = now;
          devLog(
            `Reconnecting in ${delay}ms (attempt ${
              retryContext.previousRetryCount + 1
            })`
          );
          return delay;
        },
      })
      .withServerTimeout(60000)
      .withKeepAliveInterval(30000)
      .configureLogging(
        process.env.NODE_ENV === "development" ? LogLevel.Debug : LogLevel.Error
      )
      .build();
  }

  private setupEventHandlers() {
    // Journey update handler
    this.connection.on("journeyUpdated", (data) => {
      this.journeyCallbacks.forEach((callback) => callback(data));
    });

    // Connection lifecycle handlers
    this.connection.onreconnecting((error) => {
      if (!this.isTabActive) {
        this.connectionPromise = null;
        return;
      }
      devLog("Reconnecting to hub...", error);
      this.reconnectAttempt++;
    });

    this.connection.onreconnected((connectionId) => {
      devLog("Reconnected to hub", connectionId);
      this.reconnectAttempt = 0;
    });

    this.connection.onclose((error) => {
      if (this.reconnectAttempt >= this.maxRetryAttempts) {
        devLog("Connection failed after maximum retries", error);
        this.handleConnectionFailure(error);
      } else {
        devLog("Connection closed, will retry", error);
      }
    });
  }

  private handleConnectionFailure(error: Error | undefined) {
    devLog("Connection failed, resetting connection", error);
    this.connectionPromise = null;
    if (this.isTabActive) {
      this.connection = this.createConnection();
      this.setupEventHandlers();
    }
  }

  private async ensureConnected(): Promise<void> {
    if (!this.isTabActive) return;
    if (
      this.connection.state === HubConnectionState.Disconnected &&
      !this.connectionPromise
    ) {
      const now = Date.now();
      if (now - this.lastReconnectTime >= this.minReconnectIntervalMs) {
        await this.connect();
      }
    }
  }

  async connect() {
    if (!this.connectionPromise) {
      this.connectionPromise = (async () => {
        try {
          await this.connection.start();
          devLog("Connected to account hub");
          this.reconnectAttempt = 0;
        } catch (err) {
          devLog("Failed to connect:", err);
          this.connectionPromise = null;
        }
      })();
    }
    return this.connectionPromise;
  }

  onJourneyUpdated(callback: (data: JourneyProgress) => void) {
    this.journeyCallbacks.push(callback);
    return () => {
      this.journeyCallbacks = this.journeyCallbacks.filter(
        (cb) => cb !== callback
      );
    };
  }

  async disconnect() {
    try {
      if (
        this.connection &&
        this.connection.state !== HubConnectionState.Disconnected
      ) {
        await this.connection.stop();
        this.connectionPromise = null;
      }
    } catch (err) {
      devLog("Error disconnecting:", err);
    }
  }

  async sendEchoTest(message: string) {
    try {
      await this.ensureConnected();

      if (this.connection.state === HubConnectionState.Connected) {
        await this.connection.invoke("EchoTest", message);
      } else {
        devLog("Not connected. Current state:", this.connection.state);
      }
    } catch (err) {
      devLog("Error sending echo:", err);
      throw err;
    }
  }

  onEchoResponse(callback: (message: string) => void) {
    this.connection.on("EchoResponse", (message) => {
      devLog("Echo response received:", message);
      callback(message);
    });
  }
}

export const accountHub = new AccountHubService();
