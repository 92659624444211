import { Alert, Box, TextField } from "@mui/material";
import { EditorComponent, ThemeProvider } from "@remirror/react";
import { useLayoutEffect, useRef, useState } from "react";
import { useHtmlEditorContext } from "./useHtmlEditorContext";
import ErrorBoundary from "../../contexts/ErrorBoundary";
import { logError } from "../../services/logging";

const RootEditorComponent = ({ editorId }) => {
  return (
    <ErrorBoundary
      errorContent={<Alert severity="error">Editor error</Alert>}
      onError={(error) => {
        logError(error, "RootEditorComponent: " + editorId);
      }}
    >
      <EditorComponent />
    </ErrorBoundary>
  );
};

export type HtmlEditorBoxProps = {
  onSourceEdit?: (html: string) => void;
  editorId: string;
  compact?: boolean;
  embedded?: boolean;
  maxHeight?: number;
};

export const HtmlEditorBox = ({
  onSourceEdit,
  editorId,
  compact,
  embedded,
  maxHeight,
}: HtmlEditorBoxProps) => {
  const { isSourceEdit, editedHtml, darkBkg, setEditedHtml, fullScreen } =
    useHtmlEditorContext();

  const [, setEditorWidth] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!isSourceEdit) {
      setEditorWidth(ref.current?.offsetWidth ?? 0);
    }
  }, [isSourceEdit, ref.current?.offsetWidth, setEditorWidth]);

  // Calculate editor height based on mode
  const getEditorHeight = () => {
    if (maxHeight) return `${maxHeight}px`;
    if (fullScreen) return "calc(100vh - 250px)";
    if (compact && !embedded) return "120px"; // Smaller height in compact mode
    return "auto";
  };

  return (
    <Box
      ref={ref}
      sx={{
        "& .remirror-editor-wrapper": {
          pt: 0,
          display: isSourceEdit ? "none" : "block",
          "& > div": {
            backgroundColor: darkBkg && !isSourceEdit ? "#333" : null,
          },
          "& .remirror-editor": {
            height: getEditorHeight(),
            maxHeight: maxHeight ?? "none",
            minHeight: maxHeight ?? "none",
          },
        },
      }}
    >
      <ThemeProvider>
        <RootEditorComponent editorId={editorId} />
      </ThemeProvider>

      {isSourceEdit && (
        <Box>
          <TextField
            sx={{
              mt: 1,
              width: "100%",
              height: fullScreen
                ? "calc(100vh - 250px)"
                : compact
                ? "120px"
                : "400px",
              "& .MuiInputBase-root": {
                bgcolor: "text.primary",
                color: "background.paper",
                p: 0,
                height: "100%",
                minHeight: compact ? "50px" : "100px",
              },
              "& textarea": {
                height: "90% !important",
                overflowY: "auto !important",
                p: 2,
              },
            }}
            fullWidth
            multiline
            value={editedHtml ?? ""}
            onChange={(e) => {
              setEditedHtml(e.target.value);
              onSourceEdit?.(e.target.value);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
