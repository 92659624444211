import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { EmptyState } from "../../components/EmptyState";
import { useProducts } from "../../features/products/product-func";
import { appFeatures } from "../../features/guide/features";

type Props = {
  onAdd: () => void;
  onCsvImport?: () => void;
  onSample?: () => void;
};

export const EmptyProductDb = ({ onAdd, onCsvImport, onSample }: Props) => {
  const [importAnchorEl, setImportAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const { isReadOnly } = useProducts();

  const navigate = useNavigate();

  return (
    <EmptyState
      label="Add your first product!"
      btnLabel="Add product"
      imageSrc="/images/open-box.png"
      onAdd={isReadOnly ? null : () => onAdd()}
      helpUrl={appFeatures.products.helpUrl}
    >
      {!isReadOnly && (
        <>
          <Button
            sx={{ ml: 2 }}
            variant="outlined"
            size="small"
            onClick={(event) => {
              setImportAnchorEl(event.currentTarget);
            }}
          >
            Import
          </Button>
          <Menu
            anchorEl={importAnchorEl}
            open={!!importAnchorEl}
            onClose={() => {
              setImportAnchorEl(null);
            }}
          >
            {" "}
            <MenuItem
              component={RouterLink}
              to={appFeatures.productUpload.path}
            >
              Product Upload
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (onCsvImport) {
                  onCsvImport();
                } else {
                  navigate(appFeatures.csv.path);
                }
                setImportAnchorEl(null);
              }}
            >
              CSV / Excel
            </MenuItem>
            <MenuItem component={RouterLink} to={appFeatures.shopify.path}>
              Shopify
            </MenuItem>
          </Menu>
          {onSample && (
            <Button
              sx={{ ml: 2 }}
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                onSample();
              }}
            >
              Load sample products
            </Button>
          )}
        </>
      )}
    </EmptyState>
  );
};
