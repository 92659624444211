import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, ListItemIcon, MenuItem, Typography } from "@mui/material";

import "../designer.scss";
import { useAtom } from "jotai";
import { darkTheme } from "../../../ui/theme";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FixedPanel } from "../../../components/FixedPanel";
import { WarningDialog } from "../../../components/dialogs/WarningDialog";
import { IconTypes } from "../../../components/icons.types";
import { createBuilder } from "../../../features/catalogs/catalog.builder";
import {
  PageSizeName,
  pageSizes,
} from "../../../features/catalogs/catalog.defs";
import { createTemplatePageItem } from "../../../features/catalogs/catalog.item";
import { initTemplateItems } from "../../../features/catalogs/catalog.template";
import {
  CatalogItemRef,
  createTempCatalog,
} from "../../../features/catalogs/catalogs";
import { EMPTY_GUID } from "../../../features/data/data-types";
import { generateNewEntityName } from "../../../features/data/entity";
import {
  saveTemplate,
  deleteTemplate,
} from "../../../features/templates/template.service";
import { templateDbAtom } from "../../../features/templates/template.state";
import { TemplateRef } from "../../../features/templates/templates";
import { useBlockRoute } from "../../../ui/useBlockRoute";
import { useDebouncedEffect } from "../../../ui/useDebounceEffect";
import { useDbOperations } from "../../data-func";
import { DesignBoardRef } from "../components/DesignerBoard";
import { DesignerShell } from "../components/DesignerShell";
import { useBuilder } from "../useBuilder";
import { TemplateViewMode, TemplateBoard } from "./TemplateBoard";
import { TemplateNavBar } from "./TemplateNavBar";
import { TemplateSidebar } from "./TemplateSidebar";
import { useTemplateProcessor } from "./useTemplateProcessor";
import { productDbAtom } from "../../../features/products/product.state";
import { changePageTitle } from "../../../ui/ui-util";
import { appFeatures } from "../../../features/guide/features";
import HelpButton from "../../../components/buttons/HelpButton";

export type TemplateDesignerOptions = {
  templateId?: string;
  productId?: string;
  onChange?: (templateId: string, template: TemplateRef) => void;
  onClose?: () => void;
  pageSize?: PageSizeName;
  modal?: boolean;
  canSelect?: boolean;
};

const minBarWidth = 200;

const getlastTemplateId = (templates: TemplateRef[]) =>
  templates
    .slice()
    .sort((a, b) => b.template.modified.localeCompare(a.template.modified))[0]
    ?.template.id;

export const TemplateDesigner = ({
  templateId,
  productId,
  onChange,
  onClose,
  pageSize,
  modal,
  canSelect,
}: TemplateDesignerOptions) => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleteting] = useState(false);
  const [edited, setEdited] = useState<CatalogItemRef>();
  const [copyTemplate, setCopyTemplate] = useState<CatalogItemRef>();
  const [viewProductId, setViewProductId] = useState<string>(productId);
  const [viewMode, setViewMode] = useState<TemplateViewMode>("preview");
  const [editTemplateId, setEditTemplateId] = useState<string>(templateId);
  const [deleteTemplateId, setDeleteTemplateId] = useState<string>();

  const [didAutoSave, setDidAutoSave] = useState(false);

  const [templates, setTemplates] = useAtom(templateDbAtom);
  const [productDb] = useAtom(productDbAtom);

  const { processDb } = useDbOperations();

  const boardRef = useRef<DesignBoardRef>(null);

  const [isNavOpen, setNavOpen] = useState(true);

  const { builder, setBuilder, isDirty, setIsDirty, setViewVersion } =
    useBuilder();

  const { processChange, historyApi, serializeTemplate } = useTemplateProcessor(
    builder,
    boardRef.current,
    edited,
    (persistent) => {
      setViewVersion((v) => v + 1);
      if (persistent) {
        setIsDirty(true);
      }
    }
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const isNew = editTemplateId === EMPTY_GUID;

  useEffect(() => {
    const b = createBuilder(createTempCatalog());
    b.setBoard({ pageSize: pageSize ?? pageSizes[0].name });
    b.initArgs = { useOriginal: true };
    setBuilder(b);
  }, [pageSize, setBuilder]);

  const updateItemView = useCallback(
    (mode: TemplateViewMode) => {
      builder.initItem(edited, {
        ItemId: mode === "preview" ? viewProductId : null,
      });
      setViewVersion((v) => v + 1);
    },
    [builder, edited, setViewVersion, viewProductId]
  );

  useEffect(() => {
    if (builder?.ready && viewMode === "preview" && !viewProductId) {
      const id =
        searchParams.get("productId") ??
        Array.from(builder.data.productDb.productMap.values())[0]?.id;
      if (id && productDb.productMap.has(id)) setViewProductId(id);
      else setViewMode("source");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builder?.ready, viewMode]);

  useEffect(() => {
    if (builder?.ready && !editTemplateId) {
      const id =
        searchParams.get("templateId") ??
        getlastTemplateId(templates) ??
        EMPTY_GUID;
      setEditTemplateId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builder?.ready, editTemplateId]);

  useEffect(() => {
    if (edited?.info.template?.template.name) {
      changePageTitle(edited?.info.template.template.name, "Card");
    }
  }, [edited?.info.template.template.name]);

  useEffect(() => {
    if (viewProductId && !productDb.productMap.has(viewProductId)) {
      setViewProductId(null);
      if (!modal) {
        searchParams.delete("productId");
        setSearchParams(searchParams);
      }
    }
  }, [
    modal,
    productDb.productMap,
    searchParams,
    setSearchParams,
    viewProductId,
  ]);

  useEffect(() => {
    if (!builder?.ready || !editTemplateId) return;

    if (edited && editTemplateId === edited?.info.template?.template.id) {
      updateItemView(viewMode);
    } else {
      const item = createTemplatePageItem(
        builder,
        editTemplateId,
        viewMode === "preview" ? viewProductId : null,
        0,
        0,
        false
      );
      if (isNew && copyTemplate) {
        const copy = copyTemplate.info.template.template;
        item.info.template = {
          template: {
            ...copy,
            id: EMPTY_GUID,
            name: generateNewEntityName(
              builder.data.templates,
              copy.name,
              (t) => t.template.name
            ),
            isDefault: false,
            isDefaultDetails: false,
            isDefaultList: false,
          },
        };
        item.info.templateItems = null;
        initTemplateItems(builder, { parent: item });
        setCopyTemplate(null);
      }
      setEdited(item);
      setIsDirty(false);
      historyApi.clear();
      boardRef.current?.clearTargets();
    }
    if (isNew) {
      setIsDirty(true);
    }
    if (!modal) {
      searchParams.set("templateId", editTemplateId);
      if (viewProductId) searchParams.set("productId", viewProductId);
      else searchParams.delete("productId");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    builder?.ready,
    editTemplateId,
    viewProductId,
    viewMode,
    copyTemplate,
    edited,
    isNew,
  ]);

  const endExit = useCallback(
    async (templateId?: string, template?: TemplateRef) => {
      if (templateId) {
        onChange?.(templateId, template ?? edited.info.template);
      }
      setTimeout(() => {
        if (modal) {
          onClose?.();
        } else {
          navigate("/catalogs");
        }
      });
    },
    [edited?.info.template, modal, navigate, onChange, onClose]
  );

  const saveBuilderTemplate = useCallback(
    async (withExit?: boolean, silent = false) => {
      if (!isDirty && withExit) {
        endExit(editTemplateId);
        return;
      }

      processDb(
        async () => {
          return await saveTemplate(serializeTemplate());
        },
        (result) => {
          const template = result.value;
          const newTemplates = templates
            .filter((t) => t.template.id !== template.id)
            .map((t1) => {
              const t = { ...t1, template: { ...t1.template } };
              if (template.isDefault) t.template.isDefault = false;
              if (template.isDefaultDetails)
                t.template.isDefaultDetails = false;
              if (template.isDefaultList) t.template.isDefaultList = false;
              return t;
            });

          newTemplates.push({ template });
          setTemplates(newTemplates);
          builder.data.templates = newTemplates;
          setBuilder(builder);

          setIsDirty(false);

          if (isNew) {
            edited.info.templateItems = null;
            setEditTemplateId(template.id);
          } else {
            edited.info.template = { template };
            setEdited(edited);
          }

          if (withExit) endExit(template.id, { template });
        },
        setIsSaving,
        silent ? "" : "Saved template " + edited.info.template.template.name,
        "Error saving template."
      );
    },
    [
      builder,
      editTemplateId,
      edited,
      endExit,
      isDirty,
      isNew,
      processDb,
      serializeTemplate,
      setBuilder,
      setIsDirty,
      setTemplates,
      templates,
    ]
  );

  const { ExitWarningDialog, setChange } = useBlockRoute({
    isDirty,
    autoSave: true,
    onProceed(c, cancelled) {
      if (c) {
        if (!cancelled) saveBuilderTemplate();
        setEditTemplateId(c.id);
      } else startExit();
      setIsDirty(false);
    },
    target: "a card",
  });

  const handleCopy = useCallback(async () => {
    setCopyTemplate({ ...edited });
    setEditTemplateId(EMPTY_GUID);
  }, [edited]);

  const handleDeleteTemplate = useCallback(async () => {
    const t = templates.find((t) => t.template.id === deleteTemplateId);
    processDb(
      async () => {
        return await deleteTemplate(deleteTemplateId);
      },
      () => {
        const newTemplates = templates.filter(
          (t) => t.template.id !== deleteTemplateId
        );
        setTemplates(newTemplates);
        setEditTemplateId(getlastTemplateId(newTemplates) ?? EMPTY_GUID);
        setDeleteTemplateId(null);
        setIsDirty(false);
      },
      setIsDeleteting,
      "Deleted template " + t?.template.name,
      "Error deleting template."
    );
  }, [templates, processDb, deleteTemplateId, setTemplates, setIsDirty]);

  useDebouncedEffect(
    () => {
      if (isDirty) {
        saveBuilderTemplate(false, true);
        setDidAutoSave(true);
      }
    },
    20000,
    [isDirty],
    true
  );

  const startExit = useCallback(async () => {
    boardRef.current?.api?.selectItem();
    await saveBuilderTemplate(true);
    // if (withReplace && isDirty) {
    //   await saveBuilderTemplate(true);
    //   return;
    // }
    // endExit(editTemplateId);
  }, [saveBuilderTemplate]);

  const navBar = useMemo(() => {
    if (!builder?.ready) return null;

    return (
      <TemplateNavBar
        productId={viewProductId}
        templateId={editTemplateId}
        onTemplateChange={(templateId) => {
          if (isDirty) {
            setChange({ id: templateId });
          } else {
            setEditTemplateId(templateId);
          }
        }}
      />
    );
  }, [builder?.ready, viewProductId, editTemplateId, isDirty, setChange]);

  return (
    <Box
      className="designer-page template-designer"
      id="template-designer-container"
    >
      <DesignerShell
        isDirty={isDirty}
        onSave={() => saveBuilderTemplate()}
        isSaving={isSaving}
        historyApi={historyApi}
        modal={modal}
        theme={darkTheme}
        hadSave={didAutoSave}
        cssClass="template-designer-shell"
        startToolbar={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: minBarWidth,
            }}
          >
            <Button
              variant="text"
              color="inherit"
              onClick={startExit}
              startIcon={<IconTypes.ArrowBack />}
            >
              Back {modal ? "" : "to catalogs"}
            </Button>
          </Box>
        }
        toolbar={
          <Box
            sx={{
              flex: 1,
              display: "flex",
            }}
          >
            <Typography variant="h6" component="div" sx={{ ml: 5 }}>
              Product Card Designer
            </Typography>
          </Box>
        }
        endToolbar={
          <Box>
            <Button
              variant="outlined"
              size="small"
              disabled={isNew}
              onClick={handleCopy}
            >
              Save As New
            </Button>
            {canSelect && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={startExit}
                sx={{ ml: 3 }}
              >
                Apply & Save
              </Button>
            )}
            <HelpButton
              helpUrl={appFeatures.productCard.helpUrl}
              sx={{ ml: 1 }}
            />
          </Box>
        }
        moreMenu={(onClose) => [
          <MenuItem
            key="del"
            onClick={() => {
              setDeleteTemplateId(editTemplateId);
              onClose();
            }}
            disabled={isNew}
          >
            <ListItemIcon>
              <IconTypes.DeleteAction fontSize="small" color="error" />
            </ListItemIcon>
            Delete card
          </MenuItem>,
        ]}
      >
        <Box sx={{ display: "flex" }}>
          {isNavOpen && navBar}
          <Box
            sx={{
              flexGrow: 1,
              minWidth: 600,
            }}
          >
            <FixedPanel
              container="#template-designer-container"
              breakpoint="lg"
            >
              {edited && (
                <TemplateBoard
                  ref={boardRef}
                  parentItem={edited}
                  addHistory={historyApi.addHistory}
                  onChange={processChange}
                  onSelect={() => setViewVersion((v) => v + 1)}
                  builder={builder}
                  setBuilder={setBuilder}
                  viewMode={viewMode}
                  setViewMode={(v) => {
                    updateItemView(v);
                    setViewMode(v);
                  }}
                  isNavOpen={isNavOpen}
                  onNavToggle={(isOpen) => setNavOpen(isOpen)}
                />
              )}
            </FixedPanel>
          </Box>
          <TemplateSidebar
            setBuilder={setBuilder}
            boardRef={boardRef}
            item={edited}
            onChange={(change) => {
              setViewVersion((v) => v + 1);
              processChange(change);
            }}
            onProductChange={(productId) => {
              setViewProductId(productId);
              setViewMode("preview");
            }}
            onTogglePanel={() => boardRef.current?.clearTargets()}
            //marginWidth={isNavOpen ? minBarWidth : 0}
          />
          {/* <Box className="shadow-panel">
            <FixedPanel
              overflow="auto"
              container="#template-designer-container"
            >
             
            </FixedPanel>
          </Box> */}
        </Box>
      </DesignerShell>
      <WarningDialog
        open={!!deleteTemplateId}
        onClose={() => {
          setDeleteTemplateId(null);
        }}
        onConfirm={() => {
          handleDeleteTemplate();
        }}
        title={`Delete card ${edited?.info.template.template.name}?`}
        action="Delete"
        processing={isDeleting}
        color="error"
      >
        The card will be deleted from all catalogs, grids and Showrooms.
      </WarningDialog>
      {ExitWarningDialog}
    </Box>
  );
};

export default TemplateDesigner;
