import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import { HtmlEditorProps } from "../remirror/editor.context";
import { HtmlEditor } from "../remirror/RemirrorEditor";
import { IconTypes } from "../icons.types";

export type HtmlEditorDialogProps = {
  isOpen?: boolean;
  onClose?: () => void;
} & HtmlEditorProps;

export type OpenHtmlEditorModalFunc = (params: HtmlEditorProps) => void;

export const HtmlEditorDialog = ({
  value,
  onChange,
  isOpen,
  onClose,
  fields,
  fieldArgs,
  fullScreen: isFullScreen,
  editorId,
}: HtmlEditorDialogProps) => {
  const [html, setHtml] = useState(value);
  const [fullScreen, setFullScreen] = useState(isFullScreen || false);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Text Editor
          <Tooltip title={fullScreen ? "Exit full screen" : "Full screen"}>
            <IconButton
              size="small"
              onClick={() => setFullScreen(!fullScreen)}
              aria-label={fullScreen ? "Exit full screen" : "Full screen"}
            >
              {fullScreen ? (
                <IconTypes.CloseFullscreen />
              ) : (
                <IconTypes.OpenFullscreen />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <HtmlEditor
          value={html}
          onChange={setHtml}
          fields={fields}
          fieldArgs={fieldArgs}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          editorId={"dialog-" + editorId}
          disableExternalEdit={true}
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: fullScreen ? "center" : "flex-end",
          padding: "16px 24px",
        }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onChange(html);
            onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
